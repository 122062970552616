<script setup lang="ts">

import type MapCoordinate from '@/types/MapCoordinate.ts'
import {useGameStore} from '@/stores/gameStore.ts'
import {computed} from "vue";
import {useAssetStore} from "@/stores/assetStore.ts";

const gameStore = useGameStore()
const assetStore = useAssetStore()

const props = defineProps<{
  coordinate: MapCoordinate
}>();

const starTypeData = computed(() => {
  return assetStore.findStarTypeById(props.coordinate.typeVariant)
})

</script>

<template>
  <div class="background border-slate-800 border">
    <div class="flex justify-between">
      <div class="text-center text-subtext p-1">
        Size: {{ coordinate.typeSize }}
      </div>
      <div class="text-center text-subtext p-1">
        {{ starTypeData?.name }}
      </div>
    </div>
    <img :src="'' + gameStore.cdn + 'images/stars/128/star_' + props.coordinate.typeVariant + '.png'" class="star-image"  alt=""/>
  </div>
</template>

<style scoped lang="scss">
.star-image {
  height: 10rem;
  width: 10rem;
}
.background {
  background-image: url("https://cdn.galexion.com/images/map/bg75d.jpg");
}
</style>