<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import NewsItemHeader from '@/components/Interface/Headers/NewsItemHeader.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import ToastItem from '@/components/Interface/Toast/ToastItem.vue'

const assetStore = useAssetStore();


const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

const tech = computed(() => {
  return assetStore.findTechById(props.newsItem.contentData.techId)
})


</script>

<template>
  <div v-if="props.toast">
    <ToastItem>
      <template #image>
        <ImageRounded
          type="tech"
          source-size="128"
          :file="tech.code"
          :size="3"
        />
      </template>
      <template #text>
        You have discovered {{ tech.name }}!
      </template>
    </ToastItem>
  </div>
  <div v-else>
    <ModalTrigger type="tech" :data="{'techId': newsItem.contentData.techId }" >
      <NewsItemHeader :is-read="newsItem.isRead" :turn="newsItem.turn">
        You have discovered {{ tech.name }}!
      </NewsItemHeader>
      <div class="bg-slate-800 p-2 text-subtext flex ">
        <div class="flex-shrink pr-2">
          <ImageRounded
            type="tech"
            source-size="128"
            :file="tech.code"
            :size="8"
          />
        </div>
        <div class="flex-grow ps-1">
          <div class="pb-2 flex-col">
            <div>
            {{ tech.name }} now allows you to use the following
            </div>
            <div class="flex">
              <span v-for="benefit in tech.provides" :key="benefit.id">
                <span v-if="benefit.type=='buildings'">
                  <ModalTrigger type="building" :data="{'buildingCode': benefit.code}">
                    <ImageRounded
                      type="buildings"
                      source-size="128"
                      :file="benefit.code"
                      :size="5"
                      class="mr-2 mt-2"
                    />
                  </ModalTrigger>
                </span>
                <span v-if="benefit.type=='ship_components'">
                  <ModalTrigger type="shipComponent" :data="{'shipComponentCode': benefit.code}">
                    <ImageRounded
                      type="ship-components"
                      source-size="128"
                      :file="benefit.code"
                      :size="5"
                      class="mr-2 mt-2"
                    />
                  </ModalTrigger>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ModalTrigger>
  </div>
</template>

<style scoped lang="scss">
.provide-image {
  height:2.5rem !important;
  width:2.5rem !important;
  object-fit: cover;
  border: 1px solid black;
}
</style>