<script setup lang="ts">

import { computed, defineComponent, defineProps, ref } from 'vue'
import {useAssetStore} from "@/stores/assetStore";
import Backend from "@/models/backend";
import { useGameStore } from '@/stores/gameStore'
import Icon from '@/components/Objects/Icons/Icon.vue'
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import BuildingEffects from '@/components/Objects/Building/BuildingEffects.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import BuildingBase from '@/components/Sections/Planets/PlanetTabs/BuildingBase.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'


/**
 * props
 */
const props = defineProps({
  planetId: Number,
  productionObjectId: Number
});

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const gameStore = useGameStore()
const backend = new Backend()

const showButton = ref(false);

/**
 * Computed
 */
const productionObjectData = computed(() => assetStore.findProductionObjectById(props.productionObjectId))

const buildingData = computed(() => {
  return assetStore.findBuildingByCode(productionObjectData.value.objectId)
})

/**
 * Set the production object
 */
const setProductionObject = () => {
  if(productionObjectData.value.type == 'building') {
    backend.setPlanetProductionObject(props.planetId, props.productionObjectId);
    gameStore.setTinySuccessToastMessage('Building added to production queue');
  } else {
    // Open the build ship modal
    gameStore.modalType = 'planetBuildShip';
    gameStore.modalData = {
      planetIds: [props.planetId]
    }
  }
}

</script>

<template>
  <BuildingBase class="mt-1">
    <template #image>
      <ModalTrigger v-if="productionObjectData.type=='building'" type="building" :data="{'buildingCode': productionObjectData.objectId}">
        <ImageRounded
          type="buildings"
          source-size="128"
          :file="productionObjectData.objectId"
          :size="8"/>
      </ModalTrigger>
      <ImageRounded
        v-else
        type="buildings"
        source-size="128"
        file="ship"
        :size="8"/>
    </template>
    <template #button>
      <StandardButton
        @click.once="setProductionObject"
        :end-margin="false"
      >Build</StandardButton>
    </template>
    <template #title>
      {{ productionObjectData.productionObjectName }}
    </template>
    <template #default>
      {{ productionObjectData.description }}
    </template>
    <template #effects>
      <div class="flex justify-between w-full">
        <div>
          <BuildingEffects v-if="buildingData" :building="buildingData"> </BuildingEffects>
        </div>
        <div class="flex-shrink text-subtext">{{ productionObjectData.productionCost == 0 ? '?' : productionObjectData.productionCost }} <Icon type="production"></Icon></div>
      </div>
    </template>
  </BuildingBase>
</template>

<style scoped lang="scss">
</style>
