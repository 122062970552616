<script setup lang="ts">

const props = defineProps({
  compatibility: String
});

</script>

<template>
<span v-if="props.compatibility==='A'" class="text-green-500">Excellent</span>
<span v-else-if="props.compatibility==='B'" class="text-cyan-500">Good</span>
<span v-else-if="props.compatibility==='C'" class="text-subtext">Neutral</span>
<span v-else-if="props.compatibility==='D'" class="text-amber-400">Poor</span>
<span v-else-if="props.compatibility==='E'" class="text-red-500">Bad</span>
</template>

<style scoped lang="scss">

</style>