import {defineStore} from 'pinia'
import type PlayerShip from "@/types/PlayerShip.ts";
import type PlayerShipAction from "@/types/PlayerShipAction.ts";
import {useMapStore} from "@/stores/mapStore.ts";
import { useShipStore } from '@/stores/shipStore.ts'
import type Fleet from '@/types/Fleet.ts'
import { useMapCoordinateStore } from '@/stores/mapCoordinateStore.ts'

export const useShipActionStore = defineStore('shipActionStore', {
    state: () => ({
        ships: [] as PlayerShip[],
        fleetId: 0 as number,
        actions: [] as PlayerShipAction[],
        pendingAction: '',
        pathStartX: 124,
        pathStartY: 118,
        pathEndX: 124,
        pathEndY: 118,
        initialized: false,
        requestMapReset: false,
        redrawPath: false,
        shipActionPath: [] as number[]
    }),
    actions: {
        initFleet(fleetData: Fleet) {

            this.pathStartX = fleetData.xPos;
            this.pathStartY = fleetData.yPos;
            this.pathEndX = fleetData.xPos;
            this.pathEndY = fleetData.yPos;

            this.ships = [];
            this.fleetId = fleetData.id

            this.initialized = true;
            this.requestMapReset = false;
            const mapStore = useMapStore();
            mapStore.toolTipVisible = false;
            mapStore.etaSpeed = fleetData.maxSpeed

            const mapCoordinateStore = useMapCoordinateStore();
            mapCoordinateStore.recalculateMapEtas(fleetData.xPos, fleetData.yPos, fleetData.maxSpeed);
        },
        initShip(shipData: PlayerShip) {
            this.ships = [shipData];
            this.fleetId = 0;
            this.pathStartX = shipData.xPos;
            this.pathStartY = shipData.yPos;
            this.pathEndX = shipData.xPos;
            this.pathEndY = shipData.yPos;
            this.initialized = true;
            this.requestMapReset = false;
            //Focus on the map
            const mapStore = useMapStore();
            mapStore.focusMap(shipData.xPos, shipData.yPos);
            mapStore.etaSpeed = shipData.maxSpeed
            mapStore.toolTipVisible = false;

            const mapCoordinateStore = useMapCoordinateStore();
            mapCoordinateStore.recalculateMapEtas(shipData.xPos, shipData.yPos, shipData.maxSpeed);
        },
        initShips(shipData: PlayerShip[]) {
            this.ships = shipData
            this.fleetId = 0;
            this.pathStartX = shipData[0].xPos;
            this.pathStartY = shipData[0].yPos;
            this.pathEndX = shipData[0].xPos;
            this.pathEndY = shipData[0].yPos;
            this.initialized = true;
            this.requestMapReset = false;
            //Focus on the map
            const mapStore = useMapStore();
            mapStore.focusMap(shipData[0].xPos, shipData[0].yPos);
            //get the slowest ship in the fleet
            let maxSpeed = 100;
            shipData.forEach((ship) => {
                if(ship.maxSpeed < maxSpeed) {
                    maxSpeed = ship.maxSpeed;
                }
            });
            mapStore.etaSpeed = maxSpeed;
            mapStore.toolTipVisible = false;

            const mapCoordinateStore = useMapCoordinateStore();
            mapCoordinateStore.recalculateMapEtas(shipData[0].xPos, shipData[0].yPos, maxSpeed);
        },
        initShipsFromIds(ids: number[]) {
            const shipsStore = useShipStore();
            const shipData = ids
              .map(id => shipsStore.findShipById(id))
              .filter((ship): ship is PlayerShip => ship !== undefined);

            this.initShips(shipData);
        },
        setPendingAction(action: string) {
            this.pendingAction = action;
        },
        queuePendingAction(x: number, y: number, targetPlayer: number, targetShip: number) {
            this.actions.push({
                action: this.pendingAction,
                targetX: x,
                targetY: y,
                targetPlayer: targetPlayer,
                targetShip: targetShip,
                data : {
                    name: this.pendingAction,
                    type: 1,
                    typeVariantId: 1,
                    targetPlayer: targetPlayer,
                }
            });
            this.pathEndY = y;
            this.pathEndX = x;
            this.pendingAction = '';
            this.redrawPath = true;
        },
        reset() {
            this.ships = [];
            this.actions = [];
            this.pendingAction = '';
            this.pathStartX = 0;
            this.pathStartY = 0;
            this.pathEndX = 0;
            this.pathEndY = 0;
            this.initialized = false;
            this.requestMapReset = true;
            const mapCoordinateStore = useMapCoordinateStore();
            mapCoordinateStore.resetMapEtas()
        }
    }
});
