<script setup lang="ts">
import {computed, defineComponent, defineProps, ref} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import PlanetProduction from "@/components/Sections/Planets/PlanetTabs/PlanetProduction.vue";
import PlanetBuildings from "@/components/Sections/Planets/PlanetTabs/PlanetBuildings.vue";
import PlanetResources from "@/components/Sections/Planets/PlanetTabs/PlanetResources.vue";
import PlanetDefence from "@/components/Sections/Planets/PlanetTabs/PlanetDefence.vue";

/**
 * Planet tabs component
 */
defineComponent({
  name: 'PlanetTabs',
})

const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()
const planetData = computed(() => planetStore.findPlanetById(props.planetId))
const currentTab = ref('production')

</script>

<template>
  <div class="text-sm font-medium text-center border-b text-gray-400 border-gray-700">
    <ul class="flex flex-wrap">
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'production',
               'text-blue-500 border-blue-500': currentTab === 'production',
               'text-gray-500 border-transparent': currentTab !== 'production',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'production'">
          Production
        </a>
      </li>
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'buildings',
               'text-blue-500 border-blue-500': currentTab === 'buildings',
               'text-gray-500 border-transparent': currentTab !== 'buildings',
               'inline-block p-4 border-b-2 rounded-t-lg  hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'buildings'">
          Buildings
        </a>
      </li>
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'resources',
               'text-blue-500 border-blue-500': currentTab === 'resources',
               'text-gray-500 border-transparent': currentTab !== 'resources',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'resources'">
          Resources
        </a>
      </li>
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'defence',
               'text-blue-500 border-blue-500': currentTab === 'defence',
               'text-gray-500 border-transparent': currentTab !== 'defence',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'defence'">
          Defense
        </a>
      </li>
<!--      <li class="me-2">-->
<!--        <a href="#"-->
<!--           :class="{-->
<!--               'active': currentTab === 'dyson',-->
<!--               'text-blue-500 border-blue-500': currentTab === 'dyson',-->
<!--               'text-gray-500 border-transparent': currentTab !== 'dyson',-->
<!--               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true-->
<!--           }"-->
<!--           @click="currentTab = 'dyson'">-->
<!--          Dyson-->
<!--        </a>-->
<!--      </li>-->
    </ul>
  </div>
  <div v-if="currentTab === 'production'">
    <PlanetProduction :planet-id="planetData?.id"></PlanetProduction>
  </div>
  <div v-else-if="currentTab === 'buildings'">
    <PlanetBuildings :planet-id="planetData?.id"></PlanetBuildings>
  </div>
  <div v-else-if="currentTab === 'resources'">
    <PlanetResources :planet-id="planetData?.id"></PlanetResources>
  </div>
  <div v-else-if="currentTab === 'defence'">
    <PlanetDefence :planet-id="planetData?.id"></PlanetDefence>
  </div>
<!--  <div v-else-if="currentTab === 'dyson'">-->
<!--    <PlanetDefence :planet-id="planetData?.id"></PlanetDefence>-->
<!--  </div>-->
</template>

<style scoped lang="scss">
.nav-item {
  cursor: pointer;
}
</style>
