import { defineStore } from 'pinia';
import type PlanetQueueAutomation from '@/types/PlanetQueueAutomation.ts'
import Backend from '@/models/backend.ts'

export const usePQAStore = defineStore('PQAStore', {
    state: () => ({
        filters: {
            food: true,
            production: true,
            science: true,
            energy_storage: true,
            ore_storage: true,
            ore: true,
            energy: true,
            credit: true,
            defence_shields: true,
            defence_attack_ships: true,
            scanning_range: true
        },
        queues: new Map<number, PlanetQueueAutomation>(),
        selectedQueue: 0 as number,
        selectedQueueData: [] as number,
    }),
    getters: {
        // Add any getters if needed
    },
    actions: {
        toggleFilter(key: string) {
            this.filters[key] = !this.filters[key];
        },
        updateQueue(data:PlanetQueueAutomation) {
            this.queues.set(data.id, data)
        },
        deleteQueue(key: string) {
            this.queues.delete(key)
        },
        findQueueById(id: number) {
            return this.queues.get(id)
        },
        selectQueue(id: number) {
            this.selectedQueue = id
            this.selectedQueueData = this.queues.get(id)?.queue || []
        },
        addToQueue(productObjectId:number) {
            this.selectedQueueData.push(productObjectId)
            this.saveQueue()
        },
        removeFromQueue(index: number) {
            this.selectedQueueData.splice(index, 1)
            this.saveQueue()
        },
        isInQueue(productObjectId: number) {
            return this.selectedQueueData.includes(productObjectId)
        },
        moveUp(index: number) {
            if(index > 0) {
                const temp = this.selectedQueueData[index]
                this.selectedQueueData[index] = this.selectedQueueData[index - 1]
                this.selectedQueueData[index - 1] = temp
            }
            this.saveQueue()
        },
        moveDown(index: number) {
            if(index < this.selectedQueueData.length - 1) {
                const temp = this.selectedQueueData[index]
                this.selectedQueueData[index] = this.selectedQueueData[index + 1]
                this.selectedQueueData[index + 1] = temp
            }
            this.saveQueue()
        },
        moveTop(index: number) {
            if(index > 0) {
                const temp = this.selectedQueueData[index]
                this.selectedQueueData.splice(index, 1)
                this.selectedQueueData.unshift(temp)
            }
            this.saveQueue()
        },
        moveBottom(index: number) {
            if(index < this.selectedQueueData.length - 1) {
                const temp = this.selectedQueueData[index]
                this.selectedQueueData.splice(index, 1)
                this.selectedQueueData.push(temp)
            }
            this.saveQueue()
        },
        saveQueue() {
            const backend = new Backend();
            backend.updatePQA(this.selectedQueue, this.selectedQueueData)
        }
    }
});
