<script setup lang="ts">

import PeaceRequest from '@/components/Sections/News/Types/PeaceRequest.vue'
import NAPAccepted from '@/components/Sections/News/Types/NAPAccepted.vue'
import FriendAccepted from '@/components/Sections/News/Types/FriendAccepted.vue'
import FriendRejected from '@/components/Sections/News/Types/FriendRejected.vue'
import PeaceAccepted from '@/components/Sections/News/Types/PeaceAccepted.vue'
import FriendRequest from '@/components/Sections/News/Types/FriendRequest.vue'
import NAPRejected from '@/components/Sections/News/Types/NAPRejected.vue'
import BattleReport from '@/components/Sections/News/Types/BattleReport.vue'
import PlanetSettled from '@/components/Sections/News/Types/PlanetSettled.vue'
import PeaceRejected from '@/components/Sections/News/Types/PeaceRejected.vue'
import NAPExpired from '@/components/Sections/News/Types/NAPExpired.vue'
import TechAcquired from '@/components/Sections/News/Types/TechAcquired.vue'
import BuildingComplete from '@/components/Sections/News/Types/BuildingComplete.vue'
import WarDeclared from '@/components/Sections/News/Types/WarDeclared.vue'
import NAPRequest from '@/components/Sections/News/Types/NAPRequest.vue'
import NewEmpireMet from '@/components/Sections/News/Types/NewEmpireMet.vue'
import type NewsItem from '@/types/NewsItem.ts'

const props = withDefaults(defineProps<{
  newsItem: NewsItem,
  toast?: boolean
}>(), {
  toast: false
});
</script>

<template>
  <span v-if="props.newsItem.type === 'building_complete'">
    <BuildingComplete :newsItem="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'battle_report'">
    <BattleReport :newsItem="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'war_declared'">
    <WarDeclared :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'friendship_request'">
    <FriendRequest :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'friendship_accepted'">
    <FriendAccepted :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'friendship_rejected'">
    <FriendRejected :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'peace_request'">
    <PeaceRequest :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'peace_accepted'">
    <PeaceAccepted :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'peace_rejected'">
    <PeaceRejected :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'nap_request'">
    <NAPRequest :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'nap_accepted'">
    <NAPAccepted :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'nap_rejected'">
    <NAPRejected :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'nap_expired'">
    <NAPExpired :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'tech_acquired'">
    <TechAcquired :news-item="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'planet_settled'">
    <PlanetSettled :newsItem="newsItem" :toast="toast"/>
  </span>
  <span v-else-if="newsItem.type === 'new_empire_met'">
    <NewEmpireMet :newsItem="newsItem" :toast="toast"/>
  </span>
</template>

<style scoped lang="scss">

</style>