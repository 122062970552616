<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import Eta from '@/models/eta.ts'
import SingleMulti from '@/components/Objects/SingleMulti.vue'

const gameStore = useGameStore()
const assetStore = useAssetStore()
const planetStore = usePlanetStore()

const tech = computed(() => {
  return assetStore.findTechById(gameStore.player?.currentTechId)
})

const techPercentage = computed(() => {
  if(!tech.value) {
    return 0;
  }
  const percentage = Math.floor((gameStore.player?.sciencePoints / tech.value?.cost)*100);
  if (percentage > 100) {
    return 100;
  }
  return percentage;
})

const eta = computed(() => {
  const eta = new Eta();
  const calculatedEta = eta.techETA(tech.value?.cost)
  return calculatedEta<0 ? 0 : calculatedEta;
})

</script>

<template>
  <div v-if="tech?.id" class="text-center bg-slate-800 text-xs rounded z-10 w-52">
    <div class="content-header">Currently researching</div>
    <ModalTrigger type="tech" :data="{'techId': tech.id}">
      <div class="p-2">
        <ImageRounded
          type="tech"
          source-size="256"
          :file="tech.code"
        />
      </div>
    </ModalTrigger>
    <div class="">
      {{ tech.name }}
    </div>
    <div class="flex-grow flex items-center justify-center p-2">
      <div class="w-full rounded bg-gray-700">
        <div class="bg-blue-600 text-xs text-blue-100 text-center p-0.5 leading-none rounded whitespace-nowrap" :style="'width:' + techPercentage + '%'"> {{techPercentage}}%</div>
      </div>
    </div>
    <div class="pb-2">
      ETA : {{ eta }} <SingleMulti :value="eta" single="week" multi="weeks"></SingleMulti>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>