<script setup lang="ts">

import {computed, defineComponent, defineProps, type Ref, ref, watch} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import {useAssetStore} from "@/stores/assetStore";
import type ProductionObject from "@/types/ProductionObject";
import PlanetProductionQueueObject
  from "@/components/Sections/Planets/PlanetTabs/ProductionTab/Objects/PlanetProductionQueueObject.vue";
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'

/**
 * Available planet production objects tab component
 */
defineComponent({
  name: 'PlanetProductionTabQueue',
})

const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()
const assetStore = useAssetStore()

const planetData = computed(() => planetStore.findPlanetById(props?.planetId))
const queuedProductionObjects: Ref<{object:ProductionObject,queueId:number}[]> = ref([]);

/**
 * Calculate the available production objects for the current planet
 **/
const updateList = () => {

  // Reset the list
  queuedProductionObjects.value = [];

  // Get the production queue and create a new list of production objects
  const productionQueue = planetData.value.productionQueue;

  if(productionQueue) {
    //iterate over all production objects with a for loop
    for (let i = 0; i < productionQueue.length; i++) {

      //iterate over all production objects with a for loop
      queuedProductionObjects.value.push(
          {
            object: assetStore.findProductionObjectById(productionQueue[i]['productionObjectId']),
            queueId: productionQueue[i]['id'],
            productionShipDesignId: productionQueue[i]['productionShipDesignId'],
            productionShipFleetId: productionQueue[i]['productionShipFleetId']
          }
      );
    }
  }
}

// Watch for changes in planetId and update sliders accordingly
watch(() => planetData.value.timestamp, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateList();
  }
}, { immediate: true }); // immediate: true ensures this runs on mount as well


</script>

<template>
  <div v-for="productionObject in queuedProductionObjects" :key="productionObject.object.id" class="pt-1">
    <PlanetProductionQueueObject
        :planet-id="planetData.id"
        :queue-id="productionObject.queueId"
        :production-object-id="productionObject.object.id"
        :production-ship-design-id="productionObject.productionShipDesignId"
        :production-ship-fleet-id="productionObject.productionShipFleetId"
    >
    </PlanetProductionQueueObject>
  </div>
  <div v-if="planetData.planetQueueAutomationId > 0">
    <ModalTrigger type="planetProductionAutomation" :data="{'PQAId': planetData.planetQueueAutomationId}">
      <div
        class="flex justify-center text-subtext p-10 bg-slate-800 mt-1 border border-slate-800 hover:border-slate-700"

      >Production Queue Automation</div>
    </ModalTrigger>
  </div>
  <div v-if="queuedProductionObjects.length === 0 && planetData.planetQueueAutomationId === 0">
    <div class="flex justify-center text-subtext p-10">Queue is empty</div>
  </div>
</template>

<style scoped lang="scss">
</style>
