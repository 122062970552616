<script setup lang="ts">

import { computed, defineProps } from 'vue'

const props = defineProps({
  currentHitPoints: Number,
  maxHitPoints: Number
});

const color = computed(() => {
  if (props.currentHitPoints / props.maxHitPoints > 0.75) {
    return 'bg-green-500';
  } else if (props.currentHitPoints / props.maxHitPoints > 0.25) {
    return 'bg-yellow-500';
  } else {
    return 'bg-red-500';
  }
});

const percentage = computed(() => {
  return Math.ceil((props.currentHitPoints / props.maxHitPoints) * 100);
});

</script>

<template>
  <div class="w-full bg-gray-600 rounded-full h-1.5 mb-4">
    <div
      :class="[color, 'h-1.5 rounded-full']"
      :style="'width: ' + percentage + '%'"
      :title = "'' + currentHitPoints + ' / ' + maxHitPoints + ' hit points'"
    ></div>
  </div>
</template>

<style scoped lang="scss">

</style>