<script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
  import Backend from '@/models/backend.ts'

  const props = defineProps({
    shipId: Number
  });

  const isOpen = ref(false);
  const menuRef = ref(null);

  function toggleMenu() {
    isOpen.value = !isOpen.value;
  }

  function closeMenu() {
    isOpen.value = false;
  }

  function handleClickOutside(event) {
    if (!menuRef.value.contains(event.target)) {
      closeMenu();
    }
  }

  const destroyShip = () => {
    // Disband fleet
    closeMenu();
    const backend = new Backend();
    backend.destroyShips([props.shipId]);
  }

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
  });
</script>

<template>
  <div class="relative" @click.stop="toggleMenu">
    <!-- Icon Button -->
    <button class="pt-1.5 pe-2">
      <svg class="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
        <path d="M10.83 5a3.001 3.001 0 0 0-5.66 0H4a1 1 0 1 0 0 2h1.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2h-9.17ZM4 11h9.17a3.001 3.001 0 0 1 5.66 0H20a1 1 0 1 1 0 2h-1.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 1 1 0-2Zm1.17 6H4a1 1 0 1 0 0 2h1.17a3.001 3.001 0 0 0 5.66 0H20a1 1 0 1 0 0-2h-9.17a3.001 3.001 0 0 0-5.66 0Z"/>
      </svg>
    </button>
    <!-- Dropdown Menu -->
    <div v-show="isOpen" ref="menuRef" class="absolute w-48 border border-slate-500 bg-slate-800 rounded z-50">
      <ModalTrigger type="editShipName" :data="{shipId: props.shipId}">
        <span class="block p-1 text-sm text-white hover:bg-slate-500">
          Edit name
        </span>
      </ModalTrigger>
      <span class="block p-1 text-sm text-red-500 hover:bg-slate-500" @click.once="destroyShip">
        Destroy
      </span>
    </div>
  </div>
</template>


<style scoped>
/* Add any specific styles for your dropdown here */
</style>
