<script setup lang="ts">

import MediumModel from '@/components/Interface/Modal/ModelSizes/MediumModel.vue'
import { useGameStore } from '@/stores/gameStore'
import {useAssetStore} from "@/stores/assetStore";
import {computed} from "vue";
import Icon from '@/components/Objects/Icons/Icon.vue'
import Backend from '@/models/backend.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'

const gameStore = useGameStore()
const assetStore = useAssetStore()

const techData = computed(() => assetStore.findTechById(gameStore.modalData.techId))

const isAvailable = computed(() => {
  return !gameStore.playerTechs.has(gameStore.modalData.techId);
})

const setTech = () => {
  const backend = new Backend();
    backend.setTechTarget(gameStore.modalData.techId).then(() => {
      gameStore.modalData = []
      gameStore.modalType = ''
    });
  gameStore.setTinySuccessToastMessage('Target research tech set');
}

</script>

<template>
  <MediumModel :title="techData?.name">
    <div class="p-3 md:p-3">
      <div class="lg:flex">
        <ImageRounded
          type="tech"
          source-size="512"
          :file="techData?.code"
          class="main-image"
          :size="16"
        />
        <div class="flex-grow text-subtext lg:pl-3 pl-0">
          <p class="pb-2">{{ techData?.description }}</p>
          <p class="pb-2">Cost : {{ techData?.cost }} <Icon type="science"></Icon></p>
          <div v-if="isAvailable">
            <StandardButton @click.once="setTech">Research up to this technology</StandardButton>
          </div>
        </div>
      </div>
    </div>
  </MediumModel>

</template>

<style scoped lang="scss">
.main-image {
  width:15rem;
  height:15rem;
}
</style>