<script setup lang="ts">

import {computed, defineComponent, defineProps, ref, type Ref, watch} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import {useAssetStore} from "@/stores/assetStore";
import type Ore from "@/types/Ore";
import Icon from "@/components/Objects/Icons/Icon.vue";

/**
 * Planet ore resources tab component
 */
defineComponent({
  name: 'PlanetResources',
})

const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()
const assetStore = useAssetStore()

const planetData = computed(() => planetStore.findPlanetById(props.planetId))

const resources = ref({} as {ore: Ore, amount: number}[]);

/**
 * Calculate the available production objects for the current planet
 **/
const updateList = () => {

  resources.value = [];
  for(let i = 0; i < planetData.value.resources.length; i++){
    const ore = assetStore.findOreById(planetData.value.resources[i].ore_id);
    if (!ore) {
      throw new Error(`Building with ID ${planetData.value.buildings[i]} not found.`);
    }
    resources.value.push({
      ore: ore,
      amount: planetData.value.resources[i].amount
    });
  }

}


// Watch for changes in planetId and update sliders accordingly
watch(() => planetData.value.timestamp, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateList();
  }
}, { immediate: true }); // immediate: true ensures this runs on mount as well


</script>

<template>
  <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-1 pt-1">
    <div v-if="resources.length > 0" class="flex justify-between bg-slate-800 border hover:border-slate-700 border-slate-800 p-2">
        <div>
          <Icon type="energy"></Icon>
          <span class="ps-2">Energy</span>
        </div>
        <div>
          <span class="text-subtext">{{ planetData.energy }} / {{ planetData.energyStorageCapacity }}</span>
        </div>
    </div>
    <div class="flex justify-between bg-slate-800 border hover:border-slate-700 border-slate-800 p-2" v-for="resource in resources" :key="resource.ore.id">
      <div>
        <Icon type="ore" :id="resource.ore.id"></Icon>
        <span class="ps-2">{{ resource.ore.oreName }}</span>
      </div>
      <div>
        <span class="text-subtext">{{ resource.amount }} / {{ planetData.oreStorageCapacity }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>
