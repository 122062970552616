<script setup lang="ts">

import MediumModel from '@/components/Interface/Modal/ModelSizes/MediumModel.vue'
import { useGameStore } from '@/stores/gameStore'
import Backend from '@/models/backend.ts'
import { computed } from 'vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import MediumPlusModel from '@/components/Interface/Modal/ModelSizes/MediumPlusModel.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'

const gameStore = useGameStore()
const playerRelationStore = usePlayerRelationStore()
const backend = new Backend();

const declareWar = () => {
  backend.relationDeclareWar(gameStore.modalData.relation.playerId).then(() => {

  })
}

const requestOrAccept = (type: string) => {
  backend.relationRequest(gameStore.modalData.relation.playerId, type).then(() => {

  })
}

const rejectRequest = (type: string) => {
  backend.relationReject(gameStore.modalData.relation.playerId, type).then(() => {

  })
}


const relation = computed(() => {
  return playerRelationStore.findRelationByPlayerId(gameStore.modalData.relation.playerId)
})

</script>

<template>

  <MediumPlusModel :title="relation.name">
    <div class="p-3 md:p-3">
      <div class="flex">
        <ImageRoundedUrl
          :url="relation.profileImage"
          :size="24"
        />
        <div>
          <div class="ms-6">
            <ol class="relative border-s border-gray-700 text-gray-400">

              <li class="ms-6">
                <span :class="
                {
                  'absolute flex items-center justify-center w-4 h-4 rounded-full -start-2 ring-4 ring-gray-900': true,
                  'bg-gray-800': relation.relation !== 'neutral',
                  'bg-amber-900': relation.relation === 'neutral'
                }">
                </span>
                <h3 class="font-medium leading-tight text-white">Neutral</h3>
                <div class="rounded p-1 mt-2 mb-2 italic">
                  Neutral relations are the default state of all players. It has no benefits or drawbacks.
                </div>
                <div v-if="['unknown', 'neutral'].includes(relation.relation)" class="pb-2">
                  <div v-if="relation.iRequested === 'friend'" class="p-2 bg-slate-700">
                    Friendship request was sent, waiting for a response.
                  </div>
                  <div v-else-if="relation.otherRequested === 'friend'">
                    <div class="p-2 bg-slate-700">
                      <div class="pb-2">{{ relation.name }} wants to become friends. Do you accept?</div>
                      <StandardButton @click.once="requestOrAccept('friend')">Accept</StandardButton>
                      <StandardButton color="red" @click.once="rejectRequest('friend')">Reject</StandardButton>
                    </div>
                  </div>
                  <div v-else>
                    <StandardButton @click.once="requestOrAccept('friend')">Send friendship request</StandardButton>
                  </div>
                  <div v-if="['friend', 'unknown', 'neutral'].includes(relation.relation)" class="pt-2">
                    <StandardButton color="red" @click.once="declareWar">Declare WAR!</StandardButton>
                  </div>
                </div>
              </li>

              <li class="ms-6">
                <span :class="
                {
                  'absolute flex items-center justify-center w-4 h-4 rounded-full -start-2 ring-4 ring-gray-900': true,
                  'bg-gray-800': relation.relation !== 'friend',
                  'bg-purple-800': relation.relation === 'friend'
                }">
                </span>
                <h3 class="font-medium leading-tight text-white">Friend</h3>
                <div class="rounded p-1 mt-2 mb-2 italic">
                  Friends are players who have agreed to help each other in times of need. Breaking a friendship can have consequences.
                </div>
                <div v-if="['friend'].includes(relation.relation)" class="pb-2">
                  <div v-if="relation.iRequested === 'nap'" class="p-2 bg-slate-700">
                    Non aggression pact request has been sent, waiting for a response.
                  </div>
                  <div v-else-if="relation.otherRequested === 'nap'">
                    <div class="p-2 bg-slate-700">
                      <div class="pb-2">{{ relation.name }} has offered a non aggression pact. Do you accept?</div>
                      <StandardButton @click.once="requestOrAccept('nap')">Accept</StandardButton>
                      <StandardButton color="red" @click.once="rejectRequest('nap')">Reject</StandardButton>
                    </div>
                  </div>
                  <div v-else>
                    <StandardButton @click.once="requestOrAccept('nap')">Request Non aggression pact</StandardButton>
                  </div>
                  <div v-if="['friend', 'unknown', 'neutral'].includes(relation.relation)" class="pt-2">
                    <StandardButton color="red" @click.once="declareWar">Declare WAR!</StandardButton>
                  </div>
                </div>
              </li>

              <li class="ms-6">
                <span :class="
                {
                  'absolute flex items-center justify-center w-4 h-4 rounded-full -start-2 ring-4 ring-gray-900': true,
                  'bg-gray-800': relation.relation !== 'ally',
                  'bg-cyan-800': relation.relation === 'ally'
                }">
                </span>
                <h3 class="font-medium leading-tight text-white">Ally</h3>
                <div class="rounded p-1 mt-2 mb-2 italic">
                  Allied players have a Non aggression pact for 90 turns. They cannot attack each other.
                </div>
                <div v-if="['ally'].includes(relation.relation)" class="p-2 mb-2 bg-slate-700">
                  The non aggression pact will expire in {{ relation.napExpireTurn - gameStore.turn }} turns.
                </div>
              </li>

              <li class="ms-6">
                <span :class="
                {
                  'absolute flex items-center justify-center w-4 h-4 rounded-full -start-2 ring-4 ring-gray-900': true,
                  'bg-gray-800': relation.relation !== 'enemy',
                  'bg-red-800': relation.relation === 'enemy'
                }">
                </span>
                <h3 class="font-medium leading-tight text-white">Enemy</h3>
                <div class="rounded p-1 mt-2 mb-2 italic">
                  Being at war allows you to attack the enemy and take their resources. You can also be attacked by the enemy.
                </div>
                <div v-if="['enemy'].includes(relation.relation)" class="pb-2">
                  <div v-if="relation.iRequested === 'peace'" class="p-2 bg-slate-700">
                    Request for peace has been sent, waiting for a response.
                  </div>
                  <div v-else-if="relation.otherRequested === 'peace'">
                    <div class="p-2 bg-slate-700">
                      <div class="pb-2">{{ relation.name }} has offered peace. Do you accept?</div>
                      <StandardButton @click.once="requestOrAccept('peace')">Accept</StandardButton>
                      <StandardButton color="red" @click.once="rejectRequest('peace')">Reject</StandardButton>
                    </div>
                  </div>
                  <div v-else>
                    <StandardButton @click.once="requestOrAccept('peace')">Request peace</StandardButton>
                  </div>
                </div>
              </li>

            </ol>
          </div>
        </div>
      </div>
    </div>
  </MediumPlusModel>

</template>

<style scoped lang="scss">

</style>