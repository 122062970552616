<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'

const gameStore = useGameStore()

const reloadGame = () => {
  window.location.reload()
}

</script>

<template>
  <div v-if="!gameStore.websocketInitialSyncCompleted" class="fixed inset-0 flex items-center justify-center bg-black z-50 text-white">
    <div class="flex flex-col items-center">
      <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
      <div class="pt-2">Loading...</div>
    </div>
  </div>
  <div v-else-if="gameStore.backendError">
    <div class="fixed inset-0 flex items-center justify-center bg-black z-50 text-white">
      <div class="flex flex-col items-center">
        <div class="text-2xl">Backend threw an error</div>
        <div class="pt-2 pb-2">Please report this</div>
        <div class="text-red-500">
          {{ gameStore.backendError }}
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="gameStore.gameOutOfSync">
    <div class="fixed inset-0 flex items-center justify-center bg-black z-50 text-white">
      <div class="flex flex-col items-center">
        <div class="text-2xl">Your game client got de-synchronized from our server.</div>
        <div class="pt-2 pb-2">Most likely this is because of an interruption in internet connectivity</div>
        <StandardButton @click="reloadGame()">Click to reload</StandardButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>
