<script setup lang="ts">

import {useAssetStore} from "@/stores/assetStore.ts";
import {useShipDesignCreationStore} from "@/stores/shipDesignCreationStore.ts";
import ShipDesignComponent from "@/components/Interface/Modal/Models/CreateShipDesign/ShipDesignComponent.vue";

const assetStore = useAssetStore()
const shipDesignCreationStore = useShipDesignCreationStore()

const props = defineProps({
  componentCode: String
});

const component = assetStore.findShipComponentByCode(props.componentCode)

</script>

<template>
  <ShipDesignComponent :component="component">
    <button
        @click="shipDesignCreationStore.addComponent(component)"
        class="bg-slate-500 hover:bg-slate-700 text-white py-1 px-3 rounded mt-1">Add</button>
  </ShipDesignComponent>
</template>

<style scoped lang="scss">

</style>