<script setup lang="ts">

import { usePlanetStore } from '@/stores/planetStore.ts'
import { onMounted, ref, watch } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'
import PlanetIcon from '@/components/Objects/Icons/PlanetIcon.vue'
import PlanetProductionProgressSimpleObject
  from '@/components/Sections/Planets/PlanetTabs/ProductionTab/Objects/PlanetProductionProgressSimpleObject.vue'
import router from '@/router'
import { useRoute } from 'vue-router'
import Amount from '@/components/Objects/IconCount.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import ActionBarBlock from '@/components/Interface/DataTable/ActionBar/ActionBarBlock.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import ActionBarSelect from '@/components/Interface/DataTable/ActionBar/ActionBarSelect.vue'
import { useTableStore } from '@/stores/tableStore.ts'
import PlanetMultiSelectMenu from '@/components/Interface/Menu/PlanetMultiSelectMenu.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'

const planetStore = usePlanetStore();
const tableStore = useTableStore();
const gameStore = useGameStore();
const route = useRoute();

// Load filtered ships list
const filters = (planets) => {
  return Array.from(planets.values())
    .filter(planet => planet.playerId === gameStore.player?.id)
    .filter((planet) => {
      // If no type is selected, return all
      if (!tableStore.planets.filters.selectedType || Number(tableStore.planets.filters.selectedType) === 0) {
        return true;
      }
      // Otherwise, filter based on the selected category
      return Number(planet.type) === Number(tableStore.planets.filters.selectedType);
    })
    .filter((planet) => {
      // If no type is selected, return all
      if (! tableStore.planets.filters.noProduction || tableStore.planets.filters.noProduction === 0) {
        return true;
      }
      // Otherwise, filter based on setting
      return Number(planet.productionObjectId) === 0;
    })
    .filter((planet) => {
      // If no search query, return all
      if (!tableStore.planets.filters.nameSearch || tableStore.planets.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the ship name (case-insensitive search)
      return planet.planetName.toLowerCase().includes(tableStore.planets.filters.nameSearch.toLowerCase());
    });
};

// Load a planet in the details
const selectPlanetDetails = (planetId: number) => {
  router.push('/planets/' + planetId);
};


// Load planet details from URL or the first in the list
if(Number(route.params.planetId) > 0) {
  selectPlanetDetails(Number(route.params.planetId));
}

// Preselect row if planetId is in the URL
onMounted(() => {
  if(Number(route.params.planetId) > 0) {
    // Load planet details from URL
    tableStore.planets.singleSelectedId = Number(route.params.planetId);
  } else if(tableStore.planets.singleSelectedId > 0) {
    // Load planet details from selected row
    selectPlanetDetails(tableStore.planets.singleSelectedId)
  } else if(planetStore.planets.size > 0) {
    // Load planet details from the first in the list
    tableStore.planets.singleSelectedId = filters(planetStore.planets).values().next().value.id
    selectPlanetDetails(tableStore.planets.singleSelectedId);
  }
});

watch(() => tableStore.planets.singleSelectedId, (newValue) => {
  if(Number(newValue) > 0) {
    selectPlanetDetails(Number(newValue));
  }
});
</script>

<template>
  <FerionDataTable
    :data="planetStore.planets"
    tableStoreName="planets"
    :selector="true"
    :filters="filters"
    :heightLimit="'h-[calc(100vh-4.5rem)]'"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarBlock class="mr-1 flex items-center pl-2">
        <PlanetMultiSelectMenu>
          <StandardButton>Action</StandardButton>
        </PlanetMultiSelectMenu>
      </ActionBarBlock>
      <ActionBarFiller class="mr-1"></ActionBarFiller>
      <ActionBarSearch v-model="tableStore.planets.filters.nameSearch" name="Name" placeHolder="Search..." class="mr-1"/>
      <ActionBarSelect name="Type" width="6" v-model="tableStore.planets.filters.selectedType" class="mr-1">
        <option value="0" selected="selected">All</option>-->
        <option value="2">Desert</option>
        <option value="4">Oceanic</option>
        <option value="1">Lava</option>
        <option value="3">Temperate</option>
        <option value="5">Gas Giant</option>
        <option value="6">Ice Giant</option>
      </ActionBarSelect>
      <ActionBarBlock class="flex items-center">
        <div class="bg-slate-800 flex items-center px-2 pt-0 mt-0">No production</div>
        <div class="pr-2">
          <label class="inline-flex items-center mt-1.5 cursor-pointer">
            <input type="checkbox" v-model="tableStore.planets.filters.noProduction" class="sr-only peer">
            <div class="relative w-9 h-5 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-800 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>
      </ActionBarBlock>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderTextFlex column="planetName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        Name
      </HeaderTextFlex>
      <HeaderFixedWidthIcon width="4" column="type" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <div class="flex justify-center">
          <PlanetIcon :size="2" :type="5"></PlanetIcon>
        </div>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="capturedAtTurn" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="age" title="Turn Acquired"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="population" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="population"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="populationGrowthPerTurn" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="food"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="productionPerTurn" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="production"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="sciencePerTurn" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="science"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="creditsPerTurn" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="credit"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthText width="15" column="productionObjectId" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="left">
        Production
      </HeaderFixedWidthText>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFlexWidth min-width="32">
          {{ item.planetName }}
      </CellFlexWidth>
      <CellFixedWidth width="4">
        <PlanetIcon :size="item.size" :type="item.type"></PlanetIcon>
      </CellFixedWidth>
      <CellFixedWidth width="3" class="text-subtext">
        {{ item.capturedAtTurn }}
      </CellFixedWidth>
      <CellFixedWidth width="3">
        {{ item.population }}
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <Amount :amount="item.populationGrowthPerTurn" :allowZero="true"></Amount>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <Amount :amount="item.productionPerTurn" :allowZero="true"></Amount>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <Amount :amount="item.sciencePerTurn" :allowZero="true"></Amount>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <Amount :amount="item.creditsPerTurn" :allowZero="true"></Amount>
      </CellFixedWidth>
      <CellFixedWidth width="15" align="left">
        <PlanetProductionProgressSimpleObject :planet-id="item.id"></PlanetProductionProgressSimpleObject>
      </CellFixedWidth>
    </template>

  </FerionDataTable>
</template>

<style scoped lang="scss">

</style>