<script setup lang="ts">
import { useGameStore } from '@/stores/gameStore.ts'

const gameStore = useGameStore()

const props = withDefaults(defineProps<{
  url: string,
  size?: number,
  border?: boolean
}>(), {
  border: true
})
</script>

<template>
  <img
    :src="props.url"
    alt=""
    :class="[
      'rounded',
      border ? 'border border-black' : ''
    ]"
    :style="{
      width: props.size ? props.size + 'rem' : 'auto',
      height: props.size ? props.size + 'rem' : 'auto'
    }"
  >
</template>

<style scoped lang="scss">
/* Add your styles here if needed */
</style>
