import { defineStore } from 'pinia'
import type Ore from "@/types/Ore";
import type ProductionObject from "@/types/ProductionObject";
import type Building from "@/types/Building";
import type ShipComponent from "@/types/ShipComponent.ts";
import type PlanetType from "@/types/PlanetType.ts";
import type StarType from "@/types/StarType.ts";
import type Tech from '@/types/Tech.ts'
import type Ministry from '@/types/Ministry.ts'

export const useAssetStore = defineStore('assetStore', {
    state: () => ({
        ores: [] as Record<number, Ore>,
        productionObjects: new Map<number, ProductionObject>(),
        buildings: [] as unknown as Record<string, Building>,
        shipComponents: new Map<string, ShipComponent>,
        planetTypes: new Map<number, PlanetType>(),
        starTypes: new Map<number, StarType>(),
        techs: new Map<number,Tech>(),
        ministry: new Map<string, Ministry>()
    }),
    getters: {
        findOreById: (state) => (id: number): Ore => {
            return state.ores[id]
        },
        findProductionObjectById: (state) => (id: number): ProductionObject => {
            return state.productionObjects.get(id) as ProductionObject
        },
        findProjectObjectByBuildingCode: (state) => (objectId: string): ProductionObject | undefined => {
            // find production object with type='building' and objectId = objectId
            return Object.values(state.productionObjects).find(po => po.type === 'building' && po.objectId === objectId);
        },
        findProjectObjectByShipComponentCode: (state) => (objectId: string): ProductionObject | undefined => {
            // find production object with type='shipComponent' and objectId = objectId
            return Object.values(state.productionObjects).find(po => po.type === 'shipComponent' && po.objectId === objectId);
        },
        findBuildingByCode: (state) => (id: string): Building => {
            return state.buildings[id]
        },
        findShipComponentByCode: (state) => (id: string): ShipComponent => {
            return state.shipComponents.get(id) as ShipComponent
        },
        findPlanetTypeById: (state) => (id: number): PlanetType | undefined => {
            return state.planetTypes.get(id)
        },
        findStarTypeById: (state) => (id: number): StarType | undefined => {
            return state.starTypes.get(id)
        },
        findTechById: (state) => (id: number): Tech | undefined => {
            return state.techs.get(id)
        },
        findTechsByTreeRowAndColumn: (state) => (row: number, column: number): Tech => {
            return Array.from(state.techs.values()).find(tech => tech.treeRow === row && tech.treeColumn === column) as Tech
        },
        findMinistryByCode: (state) => (code: string): Ministry => {
            return state.ministry.get(code) as Ministry
        }
    },
    actions: {
        updateMinistryByCode(code: string, data: Ministry) {
            this.ministry.set(code, data)
        },
        updateStarTypeById(id: number, data: StarType) {
            this.starTypes.set(id, data)
        },
        updatePlanetTypeById(id: number, data: PlanetType) {
            this.planetTypes.set(id, data)
        },
        updateOreById(id: number, data: Ore) {
            if (this.ores[id] !== undefined) {
                this.ores[id].id = data.id
                this.ores[id].oreName = data.oreName
                this.ores[id].rarity = data.rarity
            } else {
                this.ores[id] = data
            }
        },
        updateProductionObjectById(id: number, data: ProductionObject) {
            this.productionObjects.set(id, data)
        },
        updateBuildingById(id: string, data: Building) {
            if (this.buildings[id] !== undefined) {
                this.buildings[id].buildingCode = data.buildingCode
                this.buildings[id].buildingName = data.buildingName
                this.buildings[id].buildingDescription = data.buildingDescription
                this.buildings[id].buildingLongDescription = data.buildingLongDescription
                this.buildings[id].effects = data.effects
            } else {
                this.buildings[id] = data
            }
        },
        updateShipComponentById(id: string, data: ShipComponent) {
            this.shipComponents.set(id, data)
        },
        updateTechByCode(id: number, data: Tech) {
            this.techs.set(id, data)
        }
    }
})
