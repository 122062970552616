<script setup lang="ts">

import { onMounted, watch } from 'vue'
import router from '@/router'
import { useRoute } from 'vue-router'
import Icon from '@/components/Objects/Icons/Icon.vue'
import { useShipStore } from '@/stores/shipStore.ts'
import ShipHealth from '@/components/Objects/Progressbar/ShipHealth.vue'
import IconCount from '@/components/Objects/IconCount.vue'
import Position from '@/components/Interface/Position.vue'
import ShipMultiSelectMenu from '@/components/Interface/Menu/ShipMultiSelectMenu.vue'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import { useTableStore } from '@/stores/tableStore.ts'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import ActionBarBlock from '@/components/Interface/DataTable/ActionBar/ActionBarBlock.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import ActionBarSelect from '@/components/Interface/DataTable/ActionBar/ActionBarSelect.vue'
import TagButton from '@/components/Interface/Buttons/TagButton.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { useShipGroupStore } from '@/stores/shipGroupStore.ts'
import type PlayerShip from '@/types/PlayerShip.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'

const gameStore = useGameStore();
const shipsStore = useShipStore();
const shipGroupStore = useShipGroupStore();
const tableStore = useTableStore()
const route = useRoute();

const props = defineProps({
  withDetails: {
    type: Boolean,
    default: true
  },
  lockedToFleetId: {
    type: Number,
    default: 0
  }
});

// Load filtered ships list
const filters = (ships:Map<number,PlayerShip>) => {
  return Array.from(ships.values())
    .filter((ship) => {
      // If no category is selected, return all ships
      if (!tableStore.ships.filters.selectedAction || tableStore.ships.filters.selectedAction === '') {
        return true;
      }
      // Otherwise, filter based on the selected category
      return ship.currentActionName === tableStore.ships.filters.selectedAction;
    })
    .filter((ship) => {
      // If nothing is selected, return all ships
      if (!tableStore.ships.filters.selectedType || tableStore.ships.filters.selectedType === '') {
        return true;
      }
      // Otherwise, filter based on the selected type
      return ship.type === tableStore.ships.filters.selectedType;
    })
    .filter((ship) => {
      //On fleet screen we dont use groups
      if(props.lockedToFleetId > 0) {
        return true;
      }
      // All
      if (tableStore.ships.filters.selectedGroup === 0) {
        return true;
      }
      // Not in group
      if (tableStore.ships.filters.selectedGroup === -1) {
        return ship.shipGroupId === 0;
      }

      // Otherwise, filter based on the selected group
      return ship.shipGroupId === tableStore.ships.filters.selectedGroup;
    })
    .filter((ship) => {
      // If no search query, return all ships
      if (!tableStore.ships.filters.nameSearch || tableStore.ships.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the ship name (case-insensitive search)
      return ship.shipName.toLowerCase().includes(tableStore.ships.filters.nameSearch.toLowerCase());
    })
    .filter((ship) => {
      //Locked to fleet
      if(props.lockedToFleetId > 0) {
        return ship.fleetId == props.lockedToFleetId;
      } else {
        //No fleet
        return ship.fleetId == 0;
      }
    });
};

// Load a ship in the details
const selectShipDetails = (shipId: number) => {
  if(props.withDetails) {
    router.push('/command/ships/' + shipId);
  }
};

const openGroupModal = () => {
  gameStore.modalType = 'shipGroups';
};

const filterGroup = (groupId: number) => {
  tableStore.ships.filters.selectedGroup = groupId;
};

// Load ships details from URL or the first in the list
if(Number(route.params.shipId) > 0) {
  selectShipDetails(Number(route.params.shipId));
}

//Preselect row if planetId is in the URL
onMounted(() => {
  if(Number(route.params.shipId) > 0) {
    // Load ship details from URL
    tableStore.ships.singleSelectedId = Number(route.params.shipId);
  } else if(tableStore.ships.singleSelectedId > 0) {
    // Load ship details from selected row
    selectShipDetails(tableStore.ships.singleSelectedId)
  } else if(shipsStore.ships.size > 0) {
    // Load ship details from the first in the list
    //tableStore.ships.singleSelectedId = filters(shipsStore.ships).values().next().value.id
    //selectShipDetails(tableStore.ships.singleSelectedId);
  }
});

watch(() => tableStore.ships.singleSelectedId, (newValue) => {
  if(Number(newValue) > 0) {
    selectShipDetails(Number(newValue));
  }
});

</script>

<template>
  <div v-if="props.lockedToFleetId === 0" class="pl-1 pb-1 text-slate-400 text-xs">
      <TagButton :selected="false" @click="openGroupModal">+</TagButton>
      <TagButton :selected="tableStore.ships.filters.selectedGroup===0" @click="filterGroup(0)">All</TagButton>
      <TagButton :selected="tableStore.ships.filters.selectedGroup===-1" @click="filterGroup(-1)">Not in group</TagButton>
    <template v-for="item in shipGroupStore.shipGroups.values()" :key="item.id">
      <TagButton :selected="tableStore.ships.filters.selectedGroup===item.id" @click="filterGroup(item.id)">{{ item.name }}</TagButton>
    </template>
  </div>
  <FerionDataTable
    :data="shipsStore.ships"
    tableStoreName="ships"
    :selector="true"
    :filters="filters"
    :heightLimit="props.lockedToFleetId?'h-[calc(100vh-23.4rem)]':'h-[calc(100vh-9.2rem)]'"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarBlock class="mr-1 flex items-center pl-2">
        <ShipMultiSelectMenu :lockedToFleetId="props.lockedToFleetId">
          <StandardButton >
            Action
          </StandardButton>
        </ShipMultiSelectMenu>
      </ActionBarBlock>
      <ActionBarFiller class="mr-1"></ActionBarFiller>
      <ActionBarSearch v-model="tableStore.ships.filters.nameSearch" name="Name" placeHolder="Search..." class="mr-1"/>
      <ActionBarSelect name="Type" width="6" v-model="tableStore.ships.filters.selectedType" class="mr-1">
        <option value="">All</option>
        <option value="scout">Scout</option>
        <option value="settler">Settler</option>
        <option value="bomber">Bomber</option>
        <option value="fighter">Fighter</option>
      </ActionBarSelect>
      <ActionBarSelect name="Action" width="6" v-model="tableStore.ships.filters.selectedAction">
        <option value="">All</option>
        <option value="Idle">Idle</option>
        <option value="Move">Move</option>
        <option value="Attack">Attack</option>
        <option value="Settle">Settle</option>
        <option value="Follow">Follow</option>
        <option value="FollowAttack">Follow & Attack</option>
      </ActionBarSelect>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderTextFlex column="shipName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        Name
      </HeaderTextFlex>
      <HeaderFixedWidthIcon width="5" column="currentHitPoints" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_hit_points"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="maxSpeed" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_max_speed"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="cargoCapacity" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_cargo_capacity"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="shields" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_shields"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="attackShips" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_attack_ships"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="attackPlanets" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_attack_planets"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="scanningRange" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_scanning_range"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="repair" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_repair"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthText width="5" column="xPos" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Location
      </HeaderFixedWidthText>
      <HeaderFixedWidthText v-if="lockedToFleetId === 0" width="10" column="currentActionName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Action
      </HeaderFixedWidthText>
      <HeaderFixedWidthText v-else width="10" column="catchingUpToFleet" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Status
      </HeaderFixedWidthText>
      <HeaderFixedWidthText width="3" column="etaNextHop" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        ETA
      </HeaderFixedWidthText>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFlexWidth min-width="96">
        <div class="flex-col">
          <div>{{ item.shipName }}</div>
          <div v-if="item.shipGroupId>0" class="text-subtext text-xs">{{ shipGroupStore.getNameFromId(item.shipGroupId) }}</div>
        </div>
      </CellFlexWidth>
      <CellFixedWidth width="5">
        <ShipHealth :currentHitPoints="item.currentHitPoints" :maxHitPoints="item.maxHitPoints" class="mt-4"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.maxSpeed" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.cargoCapacity" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.shields" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.attackShips" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.attackPlanets" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.scanningRange" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.repair" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <Position :x="item.xPos" :y="item.yPos" @click.stop></Position>
      </CellFixedWidth>
      <CellFixedWidth width="10" align="center">
        <span v-if="lockedToFleetId === 0">
          {{ item.currentActionName }}
          {{ item.actions.length > 1 ? '+' + (item.actions.length-1) : ''}}
        </span>
        <span v-else>
          <span v-if="item.catchingUpToFleet == 1" class="text-amber-400">
            Reinforcement
          </span>
          <span v-else class="text-green-500">
            Core Fleet
          </span>
        </span>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        {{ item.etaNextHop }}
      </CellFixedWidth>
    </template>

  </FerionDataTable>
</template>

<style scoped lang="scss">
</style>