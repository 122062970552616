<script setup lang="ts">

import { usePlanetStore } from '@/stores/planetStore'
import { computed } from 'vue'
import PlanetIcon from '@/components/Objects/Icons/PlanetIcon.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import PlanetProductionIcon from '@/components/Objects/IconsWithCounters/PlanetProductionIcon.vue'
import PlanetResourceIcon from '@/components/Objects/IconsWithCounters/PlanetResourceIcon.vue'
import PlanetProductionProgressSimpleObject from '@/components/Sections/Planets/PlanetTabs/ProductionTab/Objects/PlanetProductionProgressSimpleObject.vue'
import { useGameStore } from "@/stores/gameStore.ts";
import router from '@/router'
import { useRoute } from 'vue-router'
import PlanetList from '@/components/Sections/Planets/PlanetList.vue'

const planetStore = usePlanetStore();
const gameStore = useGameStore();
const route = useRoute();

const planets = computed(() => {
  return Array.from(planetStore.planets.values())
    .filter(planet => planet.playerId === gameStore.player?.id)
    .slice(0, 50000); // Limit to 50 entries
});

const selectPlanetDetails = (planetId: number) => {
  router.push('/planets/' + planetId);
}

if(Number(route.params.planetId) > 0) {
  // Load from router/url
  selectPlanetDetails(Number(route.params.planetId));
} else if(planets.value.length > 0) {
  // Load the first planet in the list
  selectPlanetDetails(planets.value[0].id);
}

</script>

<template>
  <div class="bg-slate-900">
    <div class="flex flex-col md:flex-row">
      <div class="w-full md:w-1/2 p-1 mt-1 content-height-limit">
        <div class="bg-slate-850 rounded text-white">
          <router-view></router-view>
        </div>
      </div>
      <div class="w-full md:w-1/2 px-1">
        <div class="content-height-limit mt-1">
          <PlanetList></PlanetList>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
