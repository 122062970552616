<script setup lang="ts">
import { useTableStore } from '@/stores/tableStore.ts'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import PlanetIcon from '@/components/Objects/Icons/PlanetIcon.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import { useMapCoordinateStore } from '@/stores/mapCoordinateStore.ts'
import { useMapStore } from '@/stores/mapStore.ts'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import ActionBarSelect from '@/components/Interface/DataTable/ActionBar/ActionBarSelect.vue'
import PlanetCompatibility from '@/components/Objects/PlanetCompatibility.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import PlayerName from '@/components/Objects/PlayerName.vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'

const mapCoordinateStore = useMapCoordinateStore();
const mapStore = useMapStore();
const playerRelationsStore = usePlayerRelationStore();
const tableStore = useTableStore();
const gameStore = useGameStore();

// Load filtered ships list
const filters = (coords:Map<number, any>) => {
  return Array.from(coords.values())
    .filter(coord => coord.playerId !== 0 && coord.playerId !== gameStore.player?.id)
    .filter((coord) => {
      // If no search query, return all
      if (!tableStore.mapCoords.filters.nameSearch || tableStore.mapCoords.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the ship name (case-insensitive search)
      return coord.name.toLowerCase().includes(tableStore.mapCoords.filters.nameSearch.toLowerCase());
    })
    .filter((coord) => {
      if(tableStore.mapCoords.filters.selectedPlayer === '') {
        return true;
      }
      if(Number(tableStore.mapCoords.filters.selectedPlayer) === coord.playerId) {
        return true;
      }
    })
    .filter((coord) => {
      if(tableStore.mapCoords.filters.selectedCompatible === '') {
        return true;
      }

      if(tableStore.mapCoords.filters.selectedCompatible === coord.compatibility) {
        return true;
      }
    });
};

const onClick = (id:number) => {
  const data = mapCoordinateStore.planets.get(id);
  mapStore.focusMap(data?.xPos, data?.yPos);
};

</script>

<template>
  <FerionDataTable
    :data="mapCoordinateStore.planets"
    tableStoreName="mapCoords"
    :selector="false"
    :filters="filters"
    :heightLimit="'h-[calc(100vh-7.9rem)]'"
    :click="onClick"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarFiller class="mr-1"></ActionBarFiller>
      <ActionBarSearch v-model="tableStore.mapCoords.filters.nameSearch" name="Name" placeHolder="Search..." class="mr-1" width="16"/>
      <ActionBarSelect name="Compat." width="6" v-model="tableStore.mapCoords.filters.selectedCompatible" class="mr-1">
        <option value="" selected>All</option>
        <option value="A">Excellent</option>
        <option value="B">Good</option>
        <option value="C">Neutral</option>
        <option value="D">Poor</option>
        <option value="E">Bad</option>
      </ActionBarSelect>
      <ActionBarSelect name="Player" width="6" v-model="tableStore.mapCoords.filters.selectedPlayer" class="mr-1">
        <option value="" selected>All</option>
        <template v-for="player in playerRelationsStore.relations.values()" :key="player.playerId">
          <option :value="player.playerId">{{ player.name }}</option>
        </template>
      </ActionBarSelect>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderFixedWidthIcon width="4" column="type" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <div class="flex justify-center">
          <PlanetIcon :size="2" :type="5"></PlanetIcon>
        </div>
      </HeaderFixedWidthIcon>
      <HeaderTextFlex column="name" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        Planet / Player
      </HeaderTextFlex>
      <HeaderFixedWidthText width="6" column="eta" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center"  title="Distance from selected Ship/Fleet">
        Distance
      </HeaderFixedWidthText>
      <HeaderFixedWidthText width="6" column="compatibility" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Compatibility
      </HeaderFixedWidthText>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFixedWidth width="4">
        <PlanetIcon :size="item.size" :type="item.type"></PlanetIcon>
      </CellFixedWidth>
      <CellFlexWidth min-width="32">
        <div class="flex flex-col">
        <div>{{ item.name }}</div>
        <div class="text-xs"><PlayerName :player-id="item.playerId"/></div>
        </div>
      </CellFlexWidth>
      <CellFixedWidth width="6" align="center">
        <span class="text-subtext">{{ item.eta>0 && item.eta !== Infinity ?item.eta:'-' }}</span>
      </CellFixedWidth>
      <CellFixedWidth width="6" align="center">
        <PlanetCompatibility :compatibility="item.compatibility"/>
      </CellFixedWidth>
    </template>

  </FerionDataTable>
</template>

<style scoped lang="scss">

</style>