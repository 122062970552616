import { defineStore } from 'pinia'

export const useTableStore = defineStore('tableStore', {
    state: () => ({
        ships: {
            page:0 as number,
            rowsPerPage:25 as number,
            totalRows:0 as number,
            totalFilteredRows:0 as number,
            visibleRows:0 as number,
            selectedRowIds:[] as number[],
            selectedAll:false as boolean,
            sortColumn:'shipName' as string,
            sortDirection:'asc' as string,
            singleSelectedId:0 as number,
            filters: {
                selectedAction: '' as string,
                selectedType: '' as string,
                selectedFleet: '0' as string,
                nameSearch: '' as string,
                selectedGroup: 0 as number,
            }
        },
        mapShips: {
            page:0 as number,
            rowsPerPage:25 as number,
            totalRows:0 as number,
            totalFilteredRows:0 as number,
            visibleRows:0 as number,
            selectedRowIds:[] as [],
            selectedAll:false as boolean,
            sortColumn:'shipName' as string,
            sortDirection:'asc' as string,
            singleSelectedId:0 as number,
            filters: {
                selectedAction: '' as string,
                selectedType: '' as string,
                selectedFleet: '0' as string,
                nameSearch: '' as string,
            }
        },
        fleets: {
            page:0 as number,
            rowsPerPage:25 as number,
            totalRows:0 as number,
            totalFilteredRows:0 as number,
            visibleRows:0 as number,
            selectedRowIds:[] as [],
            selectedAll:false as boolean,
            sortColumn:'name' as string,
            sortDirection:'asc' as string,
            singleSelectedId:0 as number,
            filters: {
                nameSearch: '' as string,
            }
        },
        mapFleets: {
            page:0 as number,
            rowsPerPage:25 as number,
            totalRows:0 as number,
            totalFilteredRows:0 as number,
            visibleRows:0 as number,
            selectedRowIds:[] as [],
            selectedAll:false as boolean,
            sortColumn:'name' as string,
            sortDirection:'asc' as string,
            singleSelectedId:0 as number,
            filters: {
                nameSearch: '' as string,
            }
        },
        planets: {
            page:0 as number,
            rowsPerPage:25 as number,
            totalRows:0 as number,
            totalFilteredRows:0 as number,
            visibleRows:0 as number,
            selectedRowIds:[] as number[],
            selectedAll:false as boolean,
            sortColumn:'planetName' as string,
            sortDirection:'asc' as string,
            singleSelectedId:0 as number,
            filters: {
                nameSearch: '' as string,
                selectedType: '0' as string,
                noProduction: false as boolean,
            }
        },
        shipDesigns: {
            page:0 as number,
            rowsPerPage:25 as number,
            totalRows:0 as number,
            totalFilteredRows:0 as number,
            visibleRows:0 as number,
            selectedRowIds:[] as [],
            selectedAll:false as boolean,
            sortColumn:'name' as string,
            sortDirection:'asc' as string,
            singleSelectedId:0 as number,
            filters: {
                nameSearch: '' as string
            }
        },
        mapCoords: {
            page:0 as number,
            rowsPerPage:25 as number,
            totalRows:0 as number,
            totalFilteredRows:0 as number,
            visibleRows:0 as number,
            selectedRowIds:[] as [],
            selectedAll:false as boolean,
            sortColumn:'eta' as string,
            sortDirection:'asc' as string,
            singleSelectedId:0 as number,
            filters: {
                nameSearch: '' as string,
                selectedCompatible: '' as string,
                selectedPlayer: '' as string,
            }
        },
        mapCoordsFleets: {
            page:0 as number,
            rowsPerPage:25 as number,
            totalRows:0 as number,
            totalFilteredRows:0 as number,
            visibleRows:0 as number,
            selectedRowIds:[] as [],
            selectedAll:false as boolean,
            sortColumn:'eta' as string,
            sortDirection:'asc' as string,
            singleSelectedId:0 as number,
            filters: {
                selectedPlayer: '' as string,
            }
        },
        mapCoordsEnemyFleets: {
            page:0 as number,
            rowsPerPage:25 as number,
            totalRows:0 as number,
            totalFilteredRows:0 as number,
            visibleRows:0 as number,
            selectedRowIds:[] as [],
            selectedAll:false as boolean,
            sortColumn:'eta' as string,
            sortDirection:'asc' as string,
            singleSelectedId:0 as number,
            filters: {
                selectedPlayer: '' as string,
            }
        },
        shipGroups: {
            page:0 as number,
            rowsPerPage:25 as number,
            totalRows:0 as number,
            totalFilteredRows:0 as number,
            visibleRows:0 as number,
            selectedRowIds:[] as [],
            selectedAll:false as boolean,
            sortColumn:'name' as string,
            sortDirection:'asc' as string,
            singleSelectedId:0 as number,
            filters: {
            }
        }
    }),
    getters: {
    },
    actions: {
    }
})
