<script setup lang="ts">

import {computed} from 'vue'
import { useFleetStore } from '@/stores/fleetStore.ts'

import Backend from '@/models/backend.ts'
import router from "@/router";
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import ShipCurrentActions
  from '@/components/Sections/Command/Ships/ShipDetails/ShipDetailTabs/ShipActions/Current/ShipCurrentActions.vue'
import { useShipActionStore } from '@/stores/shipActionStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'


const fleetStore = useFleetStore()
const shipActionStore = useShipActionStore()
const gameStore = useGameStore()


const props = defineProps({
  fleetId: Number
});

const fleetData = computed(() => fleetStore.findFleetById(props.fleetId??0))

const initFleetAction = () => {
  router.push('/map/galaxy/')
  shipActionStore.initFleet(fleetData.value)
}

const clearFleetAction = () => {
  const backend = new Backend();
  backend.setFleetActions(props.fleetId,[])
    .then(() => {
      gameStore.setTinySuccessToastMessage('Orders cleared')
    })
    .catch((error) => {
      gameStore.setTinyErrorToastMessage(error.response.data.message)
      throw error
    })
}

</script>

<template>
  <div class="content-header mt-1">
    <div class="flex justify-between">
      <div>Action Queue</div>
      <div>
        <StandardButton @click="clearFleetAction">Clear</StandardButton>
        <StandardButton @click="initFleetAction">Set</StandardButton>
      </div>
    </div>
  </div>
  <div v-if="fleetData.actions.length === 0" class="text-center pt-4">
    <div class="pt-1 pb-3 text-subtext">Your fleet is doing nothing</div>
    <StandardButton @click="initFleetAction">Set an action</StandardButton>
  </div>
  <div v-else>
    <div v-for="action in fleetData.actions" :key="action.id">
      <ShipCurrentActions :shipActionData="action" :eta="0"/>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>