<script setup lang="ts">

import PQAQueueObject from '@/components/Interface/Modal/Models/PlanetProductionAutomation/PQAQueueAvailableObject.vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import { computed } from 'vue'
import { usePQAStore } from '@/stores/PQAStore.ts'
import PQAFilterIcon from '@/components/Interface/Modal/Models/PlanetProductionAutomation/PQAFilterIcon.vue'
import type ProductionObject from '@/types/ProductionObject.ts'
import PQAQueuedObject from '@/components/Interface/Modal/Models/PlanetProductionAutomation/PQAQueuedObject.vue'

const assetStore = useAssetStore()
const PQAStore = usePQAStore()

const list = computed(() => {
  let filtered = new Map<number, ProductionObject>();

  //Filter by building effects
  for (let [id, productionObject] of assetStore.productionObjects) {
    if(productionObject.type === 'building') {
      let show = false;
      const building = assetStore.findBuildingByCode(productionObject.objectId);
      for(let effect of building.effects) {
        if(PQAStore.filters[effect.type]) {
          show = true;
        }
      }
      if (show) {
        filtered.set(id, productionObject);
      }
    }
  }
  //Sort by production cost
  return new Map([...filtered.entries()].sort((a, b) => a[1].productionCost - b[1].productionCost));
});


</script>

<template>
    <div v-if="PQAStore.selectedQueue === 0" class="col-span-3">
      <div class="text-subtext p-6 text-center">
        Select or create a queue first
      </div>
    </div>
    <div v-if="PQAStore.selectedQueue !== 0" class="col-span-1 flex-col">
      <div class="content-header pr-1 mb-1">
        Queue <span v-if="PQAStore.selectedQueueData.length>0">({{ PQAStore.selectedQueueData.length }})</span>
      </div>
      <div class="model-list overflow-y-scroll overflow-x-hidden grid grid-cols-1 gap-1 pr-1 content-start">
        <template v-for="(productionObjectID,index) in PQAStore.selectedQueueData" :key="index">
          <PQAQueuedObject :production-object-id="productionObjectID" :index="index"/>
        </template>
        <div v-if="PQAStore.selectedQueueData.length === 0" class="text-subtext p-6 text-center">
          Select buildings to add to the queue
        </div>
      </div>
    </div>
    <div v-if="PQAStore.selectedQueue !== 0" class="col-span-2 flex-col pl-1">
      <div class="content-header pr-1 mb-1 flex justify-between">
        <div>
          Available
        </div>
        <div>
          <PQAFilterIcon type="food"/>
          <PQAFilterIcon type="production"/>
          <PQAFilterIcon type="science"/>
          <span class="pr-5"></span>
          <PQAFilterIcon type="energy_storage"/>
          <PQAFilterIcon type="ore_storage"/>
          <span class="pr-5"></span>
          <PQAFilterIcon type="ore"/>
          <PQAFilterIcon type="energy"/>
          <PQAFilterIcon type="credit"/>
          <span class="pr-5"></span>
          <PQAFilterIcon type="defence_shields"/>
          <PQAFilterIcon type="defence_attack_ships"/>
          <PQAFilterIcon type="scanning_range"/>
        </div>
      </div>
      <div class="model-list overflow-y-scroll overflow-x-hidden grid grid-cols-2 gap-1 pr-1 content-start">
        <template v-for="po in list.values()" :key="po.id">
          <PQAQueueObject :production-object-id="po.id"/>
        </template>
        <div v-if="list.size === 0" class="text-subtext p-6 text-center">
          No available buildings
        </div>
      </div>
    </div>
</template>

<style scoped lang="scss">
.model-list{
  height: calc(100vh - 12.5rem);
}
</style>