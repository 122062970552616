<script setup lang="ts">
import { useTableStore } from '@/stores/tableStore.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'

const tableStore = useTableStore()

const props = defineProps({
  tableStoreName: {
    type: String,
    required: true
  }
});

// Method to handle page change
const handlePageChange = (newPage: number) => {
  const totalPages = Math.ceil(tableStore[props.tableStoreName].totalFilteredRows / tableStore[props.tableStoreName].rowsPerPage);
  if (newPage >= 0 && newPage <= totalPages) {
    tableStore[props.tableStoreName].page = newPage;
  }
};
</script>

<template>
  <div class="flex justify-between px-2">
    <StandardButton @click="handlePageChange(tableStore[tableStoreName].page - 1)" :disabled="tableStore[tableStoreName].page === 0"><<</StandardButton>
    <span class="mr-2">Page {{ tableStore[tableStoreName].page + 1 }} of {{ Math.ceil(tableStore[tableStoreName].totalFilteredRows / tableStore[tableStoreName].rowsPerPage) }}</span>
    <StandardButton @click="handlePageChange(tableStore[tableStoreName].page + 1)" :disabled="(tableStore[tableStoreName].page + 1) >= Math.ceil(tableStore[tableStoreName].totalFilteredRows / tableStore[tableStoreName].rowsPerPage)" :end-margin="false">>></StandardButton>
  </div>
</template>

<style scoped lang="scss">

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
