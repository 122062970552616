<script setup lang="ts">

import MediumModel from '@/components/Interface/Modal/ModelSizes/MediumModel.vue'
import { useGameStore } from '@/stores/gameStore'
import {useAssetStore} from "@/stores/assetStore";
import {computed} from "vue";
import Icon from '@/components/Objects/Icons/Icon.vue'
import BuildingEffects from '@/components/Objects/Building/BuildingEffects.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'

const gameStore = useGameStore()
const assetStore = useAssetStore()

const buildingData = computed(() => assetStore.findBuildingByCode(gameStore.modalData.buildingCode))

const productionObject = computed(() => assetStore.findProjectObjectByBuildingCode(buildingData.value.buildingCode))

</script>

<template>

  <MediumModel :title="buildingData.buildingName">
    <div class="p-3 md:p-3">
      <div class="lg:flex">
        <ImageRounded
          type="buildings"
          source-size="512"
          :file="buildingData.buildingCode"
          class="main-image"
          :size="16"
        />
        <div class="flex-grow text-subtext lg:pl-3 pl-0">
          <p class="pb-4">{{ buildingData.buildingLongDescription }}</p>
          <p class="pb-4">Cost : {{ productionObject?.productionCost }} <Icon type="production"></Icon></p>
          <BuildingEffects :building="buildingData"> </BuildingEffects>
        </div>
      </div>
    </div>
  </MediumModel>

</template>

<style scoped lang="scss">
.main-image {
  width:15rem;
  height:15rem;
}
</style>