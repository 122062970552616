<script setup lang="ts">
import { RouterLink, useRoute } from 'vue-router'
import {useGameStore} from "@/stores/gameStore.ts";
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { computed } from 'vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import { version } from '@/../package.json'; // Adjust path if necessary

const appVersion = version;

const route = useRoute()
const gameStore = useGameStore()
const playerRelations = usePlayerRelationStore()

const totalDiplomacyRequests = computed(() => {
  return playerRelations.getCountOfPendingActions()
})


const buttons = [
  {
    'name': 'Map',
    'target' : '/map/galaxy',
    'activeKey': 'Map'
  },
  {
    'name': 'Planets',
    'target' : '/planets',
    'activeKey': 'Planets'
  },
  {
    'name': 'Command',
    'target' : '/command/ships',
    'activeKey': 'Command'
  },
  {
    'name': 'Research',
    'target' : '/research',
    'activeKey': 'Research'
  },
  // {
  //   'name': 'Vips',
  //   'target' : '/vips',
  //   'activeKey': 'Vips'
  // },
  {
    'name': 'Diplomacy',
    'target' : '/diplomacy/neutral',
    'activeKey': 'Diplomacy'
  },
  {
    'name': 'Government',
    'target' : '/government/cabinet',
    'activeKey': 'Cabinet'
  },
  {
    'name': 'Statistics',
    'target' : '/stats',
    'activeKey': 'Statistics'
  },
  {
    'name': 'News',
    'target' : '/news',
    'activeKey': 'News'
  }
]

</script>

<template>
  <div class="header bg-slate-600 flex items-center text-white">
    <!-- Logo -->
    <span class="pl-2 pr-2 font-bold">Ferion {{ appVersion }}</span>
    <!-- Menu -->
    <span class="text-nowrap overflow-auto hide-scrollbar pr-2">
      <RouterLink
        v-for="button in buttons"
        :key="button.name"
        :to="button.target"
      >
        <StandardButton :class="{'bg-slate-800': typeof route.name === 'string' && route.name.startsWith(button.activeKey)}">
          {{ button.name }}
          <span
            v-if="button.activeKey ==='News' && Number(gameStore.player?.unreadNewsItems) > 0"
            class="rounded bg-red-500 text-white text-xs px-1 ml-1"
          >{{ gameStore.player?.unreadNewsItems }}</span>

          <span
            v-if="button.activeKey ==='Diplomacy' && totalDiplomacyRequests > 0"
            class="rounded bg-red-500 text-white text-xs px-1 ml-1"
          >{{ totalDiplomacyRequests }}</span>
        </StandardButton>
      </RouterLink>
    </span>
  </div>
</template>

<style scoped>
.header {
  height: 2rem;
}
</style>