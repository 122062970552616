
<script setup lang="ts">

import { useStatsStore } from '@/stores/statsStore.ts'
import StatsGraph from './StatsGraph.vue';
import { onMounted } from 'vue' // Import the child component

const statsStore = useStatsStore()

onMounted(() => {
  statsStore.loadStatsForKey(statsStore.selectedKey ?? 'population')
});

</script>

<template>
  <div class="flex">
    <div class="p-1 content-height-limit overflow-y-auto">
      <ul class="flex-column space-y space-y-1 text-sm font-medium text-gray-400">
        <template v-for="(item, index) in statsStore.statsKeys.values()" :key="item.key">
          <!-- Show group header when the group changes -->
          <li v-if="index === 0 || item.group !== [...statsStore.statsKeys.values()][index - 1].group" class="content-header">
            {{ item.group }}
          </li>

          <!-- Render the item -->
          <li>
            <a
              href="#"
              :class="{
                'inline-flex items-center px-3 py-2 text-white active w-full': true,
                'bg-blue-500': statsStore.selectedKey === item.key,
                'bg-gray-800 hover:bg-gray-700 hover:text-white': statsStore.selectedKey !== item.key,
              }"
              aria-current="page"
              @click="statsStore.setSelectedKey(item.key)"
            >
              {{ item.label }}
            </a>
          </li>
        </template>
      </ul>
    </div>
    <div class="flex-grow content-height-limit pt-2 pr-1">
      <StatsGraph />
    </div>
  </div>
</template>


<style scoped>
/* Custom styling if needed */
</style>
