<script setup lang="ts">

import MediumModel from '@/components/Interface/Modal/ModelSizes/MediumModel.vue'
import { useGameStore } from '@/stores/gameStore'
import Icon from '@/components/Objects/Icons/Icon.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import Backend from '@/models/backend.ts'
import { ref } from 'vue'
import AutoAssignSettleBuildingQueue
  from '@/components/Interface/Modal/Models/MinistryCandidate/AutoAssignSettleBuildingQueue.vue'
import AutoAssignConqueyBuildingQueue
  from '@/components/Interface/Modal/Models/MinistryCandidate/AutoAssignConquerBuildingQueue.vue'

const gameStore = useGameStore()

const errorMessage = ref('')

const purchaseCandidate = () => {
  errorMessage.value = ''
  const backend = new Backend()
  backend.purchaseMinistryCandidate(gameStore.modalData.code,gameStore.modalData.candidate.code).then(() => {
    gameStore.setTinySuccessToastMessage(gameStore.modalData.candidate.name + ' has joined your cabinet!')
  }).catch((error) => {
    errorMessage.value = error.response.data.message
  })
}

</script>

<template>
  <MediumModel :title="gameStore.modalData.candidate.name">
    <div class="p-3 md:p-3">
      <div class="lg:flex">
        <!-- Image Section -->
        <div class="flex flex-col flex-shrink-0">
          <img
            :src="gameStore.modalData.candidate.image"
            alt=""
            class="rounded border border-black"
            style="width: 12rem; height: 12rem"
          >
          <div class="text-center pt-3">
            <div v-if="errorMessage" class="text-red-500 text-xs" style="max-width: 12rem;">{{ errorMessage }}</div>
            <div v-else>
              <StandardButton @click="purchaseCandidate" v-if="!gameStore.playerMinisters.has(gameStore.modalData.candidate.code)">
                Appoint
                <span v-for="n in gameStore.modalData.candidate.cost" :key="n">
                  <span class="pl-1">
                    <icon type="vip" :size="4" title="VIP Token"></icon>
                  </span>
                </span>
              </StandardButton>
            </div>
          </div>
        </div>
        <!-- Text Section -->
        <div class="flex-grow text-subtext lg:pl-3 pl-0">
          <p class="pb-2">{{ gameStore.modalData.candidate.description }}</p>
          <p class="p-2 text-green-500 bg-slate-700" v-if="gameStore.playerMinisters.has(gameStore.modalData.candidate.code)">
            <AutoAssignSettleBuildingQueue v-if="gameStore.modalData.candidate.code === 'auto_assign_settle_building_queue'"/>
            <AutoAssignConqueyBuildingQueue v-else-if="gameStore.modalData.candidate.code === 'auto_assign_capture_building_queue'"/>
            <span v-else>
              {{ gameStore.modalData.candidate.summary}}
            </span>
          </p>
        </div>
      </div>
    </div>

  </MediumModel>

</template>

<style scoped lang="scss">
.main-image {
  width:15rem;
  height:15rem;
}
</style>