<script setup lang="ts">
import { useMapStore } from '@/stores/mapStore.ts'
import { computed, watch } from 'vue'
import { useShipStore } from '@/stores/shipStore.ts'
import { usePlanetStore } from '@/stores/planetStore.ts'
import { useTableStore } from '@/stores/tableStore.ts'
import PlanetMultiSelectMenu from '@/components/Interface/Menu/PlanetMultiSelectMenu.vue'
import ShipMultiSelectMenu from '@/components/Interface/Menu/ShipMultiSelectMenu.vue'
import MapFleetMenu from '@/components/Interface/Menu/MapFleetMenu.vue'

const mapStore = useMapStore();
const shipStore = useShipStore();
const planetStore = usePlanetStore();
const tableStore = useTableStore();


const fleetIds = computed(() => {
  const ids = new Set();
  for (const ship of shipStore.ships.values()) {
    const { xPos, yPos, fleetId } = ship;
    const withinXRange = Number(xPos) >= Number(mapStore.areaBoxTL.x) && Number(xPos) <= Number(mapStore.areaBoxRB.x);
    const withinYRange = Number(yPos) <= Number(mapStore.areaBoxTL.y) && Number(yPos) >= Number(mapStore.areaBoxRB.y);

    if (withinXRange && withinYRange && fleetId > 0) {
      ids.add(fleetId);
    }
  }
  return Array.from(ids);
});


const shipIds = computed(() => {
  const ids = []; // Use a different variable name to avoid redeclaration confusion
  for (const ship of shipStore.ships.values()) {
    if (
      Number(ship.xPos) >= Number(mapStore.areaBoxTL.x) &&
      Number(ship.xPos) <= Number(mapStore.areaBoxRB.x) &&
      Number(ship.yPos) <= Number(mapStore.areaBoxTL.y) &&
      Number(ship.yPos) >= Number(mapStore.areaBoxRB.y)
    ) {
      if (ship.fleetId === 0) {
        ids.push(ship.id);
      }
    }
  }
  return ids;
});

watch(
  () => shipIds.value,
  (newShips) => {
    tableStore.ships.selectedRowIds = newShips;
    console.log('Updated shipIds:', newShips);
  },
  { immediate: true } // Trigger the watcher initially if needed
);

const hasFleetsInArea = computed(() => {
  for (const ship of shipStore.ships.values()) {
    if (Number(ship.xPos) >= Number(mapStore.areaBoxTL.x) && Number(ship.xPos) <= Number(mapStore.areaBoxRB.x) &&
      Number(ship.yPos) <= Number(mapStore.areaBoxTL.y) && Number(ship.yPos) >= Number(mapStore.areaBoxRB.y)) {
      if(ship.fleetId > 0) {
        return true;
      }
    }
  }
  return false;
});

const planetIds = computed(() => {
  let planets = [];
  for (const planet of planetStore.planets.values()) {
    if (
      Number(planet.xPos) >= Number(mapStore.areaBoxTL.x) &&
      Number(planet.xPos) <= Number(mapStore.areaBoxRB.x) &&
      Number(planet.yPos) <= Number(mapStore.areaBoxTL.y) &&
      Number(planet.yPos) >= Number(mapStore.areaBoxRB.y)
    ) {
      planets.push(planet.id);
    }
  }
  return planets;
});

// Watch and update only if `planetsInArea` changes
watch(
  () => planetIds.value,
  (newPlanets) => {
    tableStore.planets.selectedRowIds = newPlanets
  },
  { immediate: true } // Trigger the watch initially if needed
);




</script>



<template>
  <div v-if="Object.keys(shipIds).length>0 || Object.keys(planetIds).length>0" class="absolute" :style="{ left: mapStore.areaMouseEnd.x + 'px', top: mapStore.areaMouseEnd.y + 'px' }">
    <div class="flex">

      <div v-if="Object.keys(shipIds).length>0">
        <ShipMultiSelectMenu :locked-to-fleet-id="0">
          <img
            src="https://cdn.galexion.com/images/map/ship.png"
            alt="Ships"
            title="Ships"
            class="p-1 border border-slate-700 hover:bg-slate-800 bg-slate-900 cursor-pointer"
          >
        </ShipMultiSelectMenu>
      </div>
      <div v-if="hasFleetsInArea">
        <MapFleetMenu :fleet-ids="fleetIds">
          <img
            src="https://cdn.galexion.com/images/map/fleet.png"
            alt="Fleets"
            title="Fleets"
            class="p-1 border border-slate-700 hover:bg-slate-800 bg-slate-900 cursor-pointer"
          >
        </MapFleetMenu>
      </div>
      <div v-if="Object.keys(planetIds).length>0">
        <PlanetMultiSelectMenu>
          <img src="https://cdn.galexion.com/images/map/planet.png" alt="Planets" :title="Object.keys(planetIds).length + ' Planets'" class="p-1 border border-slate-700 hover:bg-slate-800 bg-slate-900 cursor-pointer">
        </PlanetMultiSelectMenu>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">

</style>