<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import NewsItemHeader from '@/components/Interface/Headers/NewsItemHeader.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import ToastItem from '@/components/Interface/Toast/ToastItem.vue'

const playerRelationStore = usePlayerRelationStore();

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

</script>

<template>
  <div v-if="props.toast">
    <ToastItem>
      <template #image>
        <ImageRoundedUrl
          :url="props.newsItem.contentData.profileImage"
          :size="8"
        />
      </template>
      <template #text>
        {{ props.newsItem.contentData.nickname }} has rejected your Non-Aggression Pact.
      </template>
    </ToastItem>
  </div>
  <div v-else>
    <ModalTrigger type="requestAudience" :data="{'relation': playerRelationStore.findRelationByPlayerId( props.newsItem.contentData.playerId)}" >
      <NewsItemHeader :is-read="newsItem.isRead" :turn="newsItem.turn">
        {{ props.newsItem.contentData.nickname }} has rejected your NAP
      </NewsItemHeader>
      <div class="bg-slate-800 p-2 text-subtext flex ">
        <div class="flex-shrink pr-2">
          <ImageRoundedUrl
            :url="props.newsItem.contentData.profileImage"
            :size="8"
          />
        </div>
        <div class="flex-grow ps-1">
          <div class="pb-2">
            {{ props.newsItem.contentData.nickname }} has rejected your Non-Aggression Pact.
          </div>
        </div>
      </div>
    </ModalTrigger>
  </div>
</template>

<style scoped lang="scss">

</style>