<script setup lang="ts">
import { useTableStore } from '@/stores/tableStore.ts'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import { useMapStore } from '@/stores/mapStore.ts'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import HeaderFixedWidthTextNoSort from '@/components/Interface/DataTable/Headers/HeaderFixedWidthTextNoSort.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import { useFleetStore } from '@/stores/fleetStore.ts'
import { useShipStore } from '@/stores/shipStore.ts'
import { useShipActionStore } from '@/stores/shipActionStore.ts'
import type Fleet from '@/types/Fleet.ts'

const tableStore = useTableStore();
const fleetStore = useFleetStore()
const mapStore = useMapStore();
const shipStore = useShipStore();
const shipActionStore = useShipActionStore();

//Load filtered fleet list
const filters = (fleets:Map<number, Fleet>) => {
  return Array.from(fleets.values())
    .filter((fleet) => {
      // If no search query, return all
      if (!tableStore.fleets.filters.nameSearch || tableStore.fleets.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the name (case-insensitive search)
      return fleet.name.toLowerCase().includes(tableStore.fleets.filters.nameSearch.toLowerCase());
    });
}

const onClick = (id:number) => {
  const fleet = fleetStore.fleets.get(id);
  if(fleet) {
    mapStore.focusMap(fleet.xPos, fleet.yPos);
  }
};

const onDoubleClick = (id:number) => {
  const fleet = fleetStore.fleets.get(id);
  if(fleet) {
    shipActionStore.initFleet(fleet);
  }
};

</script>false

<template>
  <FerionDataTable
    tableStoreName="fleets"
    :selector="false"
    :filters="filters"
    :heightLimit="'h-[calc(100vh-7.9rem)]'"
    :data="fleetStore.fleets"
    :click="onClick"
    :double-click="onDoubleClick"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarFiller class="mr-1"></ActionBarFiller>
      <ActionBarSearch v-model="tableStore.fleets.filters.nameSearch" name="Name" placeHolder="Search..." class="mr-1"/>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderTextFlex column="name" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" class="pl-2">
        Name
      </HeaderTextFlex>
      <HeaderFixedWidthText width="3" align="center"  column="healthPercentage" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_hit_points"></Icon>
      </HeaderFixedWidthText>
      <HeaderFixedWidthTextNoSort width="3" align="center">
        Core
      </HeaderFixedWidthTextNoSort>
      <HeaderFixedWidthTextNoSort width="3" align="center">
        <span title="Reinforcements">Re.</span>
      </HeaderFixedWidthTextNoSort>
      <HeaderFixedWidthText width="10" column="currentActionName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Action
      </HeaderFixedWidthText>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFlexWidth min-width="96" class="pl-2">
        <div class="flex-col">
          <div>{{ item.name }}</div>
        </div>
      </CellFlexWidth>
      <CellFixedWidth width="3" align="center">
        {{ item.healthPercentage }}%
      </CellFixedWidth>
      <CellFixedWidth width="3" align="center">
        <span class="text-subtext">{{ shipStore.countShipsInFleet(item.id) }}</span>
      </CellFixedWidth>
      <CellFixedWidth width="3" align="center">
        <span class="text-subtext">{{ shipStore.countShipsInFleetCatchingUp(item.id) }}</span>
      </CellFixedWidth>
      <CellFixedWidth width="10" align="center">
        {{ item.currentActionName }}
        {{ item.actions.length > 1 ? '+' + (item.actions.length-1) : ''}}
      </CellFixedWidth>
    </template>

  </FerionDataTable>
</template>

<style scoped lang="scss">

</style>