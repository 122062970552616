<script setup lang="ts">

import {ref} from "vue";
import {useShipStore} from "@/stores/shipStore.ts";
import router from '@/router'
import { useRoute } from 'vue-router'
import ShipsList from '@/components/Sections/Command/Ships/ShipsList.vue'

const shipStore = useShipStore();
const route = useRoute();

const selectedShipId = ref(0);

const selectShipDetails = (shipId: number) => {
  //set Router
  router.push(`/command/ships/${shipId}`);
}

//Load the first ship in the list
if (selectedShipId.value === 0 && shipStore.ships.size > 0) {
  selectShipDetails(shipStore.ships.values().next().value.id);
}

if(Number(route.params.shipId) > 0) {
  // Load from router/url
  selectShipDetails(Number(route.params.shipId));
} else if(shipStore.ships.size > 0) {
  // Load the first planet in the list
  selectShipDetails(shipStore.ships.values().next().value.id);
}


</script>

<template>
  <div class="bg-slate-900">
    <div class="flex flex-col md:flex-row">
      <div class="w-full md:w-3/5">
        <div class="content-height-limit-tab pt-1 pb-1">
          <ShipsList></ShipsList>
        </div>
      </div>
      <div class="w-full md:w-2/5 content-height-limit-tab pl-1 pt-1 pr-1">
        <div class="bg-slate-850 rounded text-white">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>