<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import {useGameStore} from "@/stores/gameStore.ts";
import Position from '@/components/Interface/Position.vue'
import PlayerName from '@/components/Objects/PlayerName.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import NewsItemHeader from '@/components/Interface/Headers/NewsItemHeader.vue'
import ToastItem from '@/components/Interface/Toast/ToastItem.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'

const gameStore = useGameStore();
const playerRelationStore = usePlayerRelationStore();

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

</script>

<template>
  <div v-if="props.toast">
    <ToastItem>
      <template #image>
        <ImageRoundedUrl
          :url="playerRelationStore.getProfileImageByPlayerId(newsItem.contentData.opponentPlayerId)"
          :size="8"
        />
      </template>
      <template #text>
        <span v-if="newsItem.contentData.winnerPlayerId === gameStore.player?.id">
            You have won the battle against <PlayerName :playerId="props.newsItem.contentData.opponentPlayerId" />
          </span>
        <span v-else>
            You have lost the battle against <PlayerName :playerId="props.newsItem.contentData.opponentPlayerId" />
          </span>
      </template>
    </ToastItem>
  </div>
  <div v-else>
    <ModalTrigger type="battleReport" :data="{'battleReportId': props.newsItem.contentData.battleReportId}">
      <NewsItemHeader :is-read="newsItem.isRead" :turn="newsItem.turn">
        Battle report: <span v-if="props.newsItem.contentData.winnerPlayerId === gameStore.player?.id" class="text-green-500">Victory!</span><span v-else class="text-red-500">Defeat</span>
      </NewsItemHeader>
      <div class="bg-slate-800 p-2 text-subtext flex h-36">
        <div class="flex-shrink">
          <span v-if="newsItem.contentData.winnerPlayerId === gameStore.player?.id">
            You have won
          </span>
          <span v-else>
            You have lost
          </span>
          the battle on <Position @click.stop :x="props.newsItem.contentData.xPos" :y="props.newsItem.contentData.yPos" /> against <PlayerName :playerId="props.newsItem.contentData.opponentPlayerId" />
          <br>
          <div class="pb-1 pt-8">
            <StandardButton>Replay Battle</StandardButton>
          </div>
        </div>
        <div class="flex-grow ps-1">
          <div class="pb-2">
          </div>
        </div>
      </div>
    </ModalTrigger>
  </div>
</template>

<style scoped lang="scss">

</style>