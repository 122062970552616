<script setup lang="ts">

import { computed } from 'vue'
import { useShipStore } from '@/stores/shipStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import ShipStatIcons from "@/components/Objects/IconsWithCounters/ShipStatIcons.vue";
import ShipDetailsTabs from "@/components/Sections/Command/Ships/ShipDetails/ShipDetailsTabs.vue";
import Icon from "@/components/Objects/Icons/Icon.vue";
import Position from "@/components/Interface/Position.vue";
import ShipMenu from '@/components/Interface/Menu/ShipMenu.vue';
import { useFleetStore } from '@/stores/fleetStore.ts'

const shipStore = useShipStore()
const fleetStore = useFleetStore()
const gameStore = useGameStore()

const props = defineProps({
  shipId: Number
});

const shipData = computed(() => {
  return shipStore.findShipById(props.shipId ?? 0)
})

</script>

<template>
  <div v-if="props.shipId !==0 && shipData">
    <div class="shipHeader flex flex-col justify-between bg-cover" :style="'background-image: url(' + gameStore.cdn + 'images/ship/' + shipData.type + '.webp)'">
      <div>
        <h2 class="text-2xl p-1 flex bg-black bg-opacity-50" >
          <ShipMenu :shipId="props.shipId"></ShipMenu>
          {{ shipData.shipName }}
          <Position :x="shipData.xPos" :y="shipData.yPos" class="ps-1"></Position>
        </h2>
      </div>
    </div>
    <div class="flex justify-between w-full bg-slate-700 p-2">
        <span class="flex-1 whitespace-nowrap">
          Location
        </span>
        <span class="flex-1 whitespace-nowrap text-center">
          <Icon type="ship_hit_points" class="pr-1"/> {{ shipData.currentHitPoints }} / {{ shipData.maxHitPoints }}
        </span>
        <span class="flex-1 whitespace-nowrap text-right">
          {{ fleetStore.getFleetNameById(shipData.fleetId) }}
        </span>
    </div>
    <div class="pt-1">
      <div class="grid grid-cols-3 xl:grid-cols-7 gap-1">
        <div class="bg-slate-800 text-subtext text-center p-2">
          vs Ships
          <div class="flex justify-center p-3">
            <ShipStatIcons type="ship_attack_ships" :amount="shipData.attackShips" />
          </div>
        </div>
        <div class="bg-slate-800 text-center text-subtext p-2">
          vs Planets
          <div class="flex justify-center p-3">
            <ShipStatIcons type="ship_attack_planets" :amount="shipData.attackPlanets" />
          </div>
        </div>
        <div class="bg-slate-800 text-center text-subtext p-2">
          Shields
          <div class="flex justify-center p-3">
            <ShipStatIcons type="ship_shields" :amount="shipData.shields" />
          </div>
        </div>
        <div class="bg-slate-800 text-subtext text-center p-2">
          Speed
          <div class="flex justify-center p-3">
            <ShipStatIcons type="ship_max_speed" :amount="shipData.maxSpeed" />
          </div>
        </div>
        <div class=" bg-slate-800 text-center text-subtext p-2">
          Scanning
          <div class="flex justify-center p-3">
            <ShipStatIcons type="ship_scanning_range" :amount="shipData.scanningRange" />
          </div>
        </div>
        <div class=" bg-slate-800 text-center text-subtext p-2">
          Cargo
          <div class="flex justify-center p-3">
            <ShipStatIcons type="ship_cargo_capacity" :amount="shipData.cargoCapacity" />
          </div>
        </div>
        <div class=" bg-slate-800 text-center text-subtext p-2">
          Repair
          <div class="flex justify-center p-3">
            <ShipStatIcons type="ship_repair" :amount="shipData.repair" />
          </div>
        </div>
      </div>
    </div>
    <ShipDetailsTabs :shipId="props.shipId"></ShipDetailsTabs>
  </div>
</template>

<style scoped lang="scss">
.shipHeader{
  height: 12rem;
  background-size: cover;
  background-position: center;
}
</style>