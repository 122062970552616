import { defineStore } from 'pinia'
import type PlayerPlanet from '@/types/PlayerPlanet'
import {useAssetStore} from "@/stores/assetStore.ts";
import type Building from "@/types/Building.ts";

export const usePlanetStore = defineStore('planetStore', {
    state: () => ({
        planets: new Map<number, PlayerPlanet>(),
        tempPlanet: null,
        planetBuffer: new Map<number, PlayerPlanet>(), // Buffer for batch updates
        isBatching: false, // To track batching status
    }),
    getters: {
        findPlanetById: (state) => (id: number): PlayerPlanet | undefined => {
            return state.planets.get(id)
        },
        allPlanetsArray: (state) => {
            return Array.from(state.planets.values());
        }
    },
    actions: {
        // Add planets to buffer for batching
        bufferPlanetUpdate(id: number, data: PlayerPlanet) {

            // Allow receiving of partial updates
            if(this.planets.has(id)) {
                const item = this.planets.get(id);
                // Manually merge changes
                for (const key in data) {
                    // @ts-ignore
                    if(data[key] !== undefined) {
                        // @ts-ignore
                        item[key] = data[key];
                    }
                }
                // @ts-ignore
                this.planetBuffer.set(id, item);
            } else {
                this.planetBuffer.set(id, data);
            }

            // If not already batching, start batching
            if (!this.isBatching) {
                this.startBatching();
            }
        },
        // Perform batch updates every 100ms
        startBatching() {
            this.isBatching = true;
            setInterval(() => {
                // If there are items in the buffer, commit them to the planets map
                if (this.planetBuffer.size > 0) {
                    for (const [id, data] of this.planetBuffer) {
                        this.planets.set(id, data);
                    }
                    // Clear the buffer after committing the changes
                    this.planetBuffer.clear();
                }
            }, 50);
        },
        getNumberOfShipsInProduction(shipDesignId: number): number {
          let count = 0;
          for (const planet of this.planets.values()) {
            count += planet.productionShipDesignId === shipDesignId ? 1 : 0;
          }
          return count;
        },
        getTotalCreditOutput() {
            let credits = 0;
            for(const planet of this.planets.values()) {
                credits += planet.creditsPerTurn
            }
            return credits;
        },
        getTotalScienceOutput() {
            let science = 0;
            for(const planet of this.planets.values()) {
                science += planet.sciencePerTurn
            }
            return science;
        },
        getActiveDefenceBuildings(planetId: number) {
            const assetStore = useAssetStore();

            const planet = this.planets.get(planetId);
            if (!planet) {
                return [];
            }
            const activeDefenceBuildings: Building[] = [];
            planet.buildings.forEach(buildingCode => {
                const building = assetStore.findBuildingByCode(buildingCode);
                building.effects.forEach((effect: any) => {
                    if (effect.type === 'defence_attack_ships') {
                        activeDefenceBuildings.push(building);
                    }
                });
            });

            return activeDefenceBuildings;
        },
        getPassiveDefenceBuildings(planetId: number) {
            const assetStore = useAssetStore();

            const planet = this.planets.get(planetId);
            if (!planet) {
                return [];
            }
            const passiveDefenceBuildings: Building[] = [];
            planet.buildings.forEach(buildingCode => {
                const building = assetStore.findBuildingByCode(buildingCode);
                building.effects.forEach((effect: any) => {
                    if (effect.type === 'defence_shields') {
                        passiveDefenceBuildings.push(building);
                    }
                });
            });

            return passiveDefenceBuildings;
        },
        hasBuilding(planetId: number, requiredBuildingCode: string) {
            const planet = this.planets.get(planetId);
            if (!planet) {
                return false;
            }
            for (const buildingCode of planet.buildings) {
                if (buildingCode === requiredBuildingCode) {
                    return true; // Found the required building, so return true immediately
                }
            }
            return false;
        },
        updatePlanetById(id: number, data: PlayerPlanet) {
            this.bufferPlanetUpdate(id, data);
        },
        deletePlanetById(id: number) {
            if (this.planets.has(id)) {
                this.planets.delete(id)
            }
        },
        getUnitById(id: number): PlayerPlanet | undefined {
            const planet = this.planets.get(id)
            if (planet !== undefined) {
                return planet
            } else {
                console.log('Planet not found: ' + id)
            }
        }
    }
})
