<script setup lang="ts">

import FullScreenModel from '@/components/Interface/Modal/ModelSizes/FullScreenModel.vue'
import AddNewProductionAutomation
  from '@/components/Interface/Modal/Models/PlanetProductionAutomation/PQAAdd.vue'
import PQABuilder from '@/components/Interface/Modal/Models/PlanetProductionAutomation/PQABuilder.vue'
import { usePQAStore } from '@/stores/PQAStore.ts'
import PQAItem from '@/components/Interface/Modal/Models/PlanetProductionAutomation/PQAItem.vue'
import { nextTick, onMounted } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'

const PQAStore = usePQAStore()
const gameStore = useGameStore();

onMounted(async () => {
  await nextTick(); // Ensure the DOM is fully rendered
  if(gameStore.modalData && gameStore.modalData?.PQAId) {
    PQAStore.selectQueue(gameStore.modalData.PQAId)
    gameStore.modalData = [];
  }
});

</script>

<template>

  <FullScreenModel title="Planet Production Automation">
    <div class="p-3 md:p-3 text-subtext model-content bg-slate-900">
      <div class="grid grid-cols-4 pt-2">
        <div class="col-span-1">
          <div class="pr-1 text-slate-400">
            <div class="content-header">
              Production Automations
            </div>
            <div class="grid grid-cols-1 pa-list overflow-y-auto pr-1 mt-2 content-start">
              <template v-for="pqa in PQAStore.queues.values()" :key="pqa.id">
                <PQAItem :pqa="pqa"></PQAItem>
              </template>
              <div v-if="PQAStore.queues.size === 0">
                <div class="text-subtext p-6 text-center">You don't have any queues yet, create one first.</div>
              </div>
            </div>
          </div>
          <div class="pt-1 pr-1">
            <AddNewProductionAutomation></AddNewProductionAutomation>
          </div>
        </div>
        <PQABuilder></PQABuilder>
      </div>
    </div>
  </FullScreenModel>

</template>

<style scoped lang="scss">
.model-content{
  height: calc(100vh - 8rem);
}
.pa-list {
  height: calc(100vh - 23.2rem);
}
</style>