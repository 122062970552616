<script setup lang="ts">

import { useFleetStore } from '@/stores/fleetStore.ts'
import router from '@/router'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import ActionBarBlock from '@/components/Interface/DataTable/ActionBar/ActionBarBlock.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import { useTableStore } from '@/stores/tableStore.ts'
import HeaderFixedWidthTextNoSort from '@/components/Interface/DataTable/Headers/HeaderFixedWidthTextNoSort.vue'
import { useShipStore } from '@/stores/shipStore.ts'
import { onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import Position from '@/components/Interface/Position.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'

const fleetStore = useFleetStore()
const shipStore = useShipStore()
const tableStore = useTableStore()
const route = useRoute();

//Load filtered fleet list
const filters = (fleets) => {
  return Array.from(fleets.values())
    .filter((fleet) => {
      // If no search query, return all
      if (!tableStore.fleets.filters.nameSearch || tableStore.fleets.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the name (case-insensitive search)
      return fleet.name.toLowerCase().includes(tableStore.fleets.filters.nameSearch.toLowerCase());
    });
}

// Load a fleet in the details
const selectFleetDetails = (fleetId: number) => {
  router.push('/command/fleets/' + fleetId);
};

// Load ships details from URL or the first in the list
if(Number(route.params.fleetId) > 0) {
  selectFleetDetails(Number(route.params.fleetId));
}

//Preselect row if planetId is in the URL
onMounted(() => {
  if(Number(route.params.shipId) > 0) {
    // Load ship details from URL
    tableStore.fleets.singleSelectedId = Number(route.params.shipId);
  } else if(tableStore.fleets.singleSelectedId > 0) {
    // Load ship details from selected row
    selectFleetDetails(tableStore.fleets.singleSelectedId)
  } else if(fleetStore.fleets.size > 0) {
    // Load ship details from the first in the list
    tableStore.fleets.singleSelectedId = filters(fleetStore.fleets).values().next().value.id
    selectFleetDetails(tableStore.fleets.singleSelectedId);
  }
});

watch(() => tableStore.fleets.singleSelectedId, (newValue) => {
  if(Number(newValue) > 0) {
    selectFleetDetails(Number(newValue));
  }
});


</script>

<template>
  <FerionDataTable
    tableStoreName="fleets"
    :selector="false"
    :filters="filters"
    :heightLimit="'h-[calc(100vh-7.9rem)]'"
    :data="fleetStore.fleets"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarBlock class="mr-1 flex items-center">
        <ModalTrigger type="createFleet" class="pl-2">
          <StandardButton>
            Create
          </StandardButton>
        </ModalTrigger>
      </ActionBarBlock>
      <ActionBarFiller class="mr-1"></ActionBarFiller>
      <ActionBarSearch v-model="tableStore.fleets.filters.nameSearch" name="Name" placeHolder="Search..." class="mr-1"/>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderTextFlex column="name" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" class="pl-2">
        Name
      </HeaderTextFlex>
      <HeaderFixedWidthText width="3" align="center"  column="healthPercentage" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_hit_points"></Icon>
      </HeaderFixedWidthText>
      <HeaderFixedWidthTextNoSort width="5" align="center">
        Core Fleet
      </HeaderFixedWidthTextNoSort>
      <HeaderFixedWidthTextNoSort width="6" align="center">
        Reinforcements
      </HeaderFixedWidthTextNoSort>
      <HeaderFixedWidthText width="5" column="xPos" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Location
      </HeaderFixedWidthText>
      <HeaderFixedWidthText width="10" column="currentActionName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Action
      </HeaderFixedWidthText>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFlexWidth min-width="96" class="pl-2">
        <div class="flex-col">
          <div>{{ item.name }}</div>
        </div>
      </CellFlexWidth>
      <CellFixedWidth width="3" align="center">
        {{ item.healthPercentage }}%
      </CellFixedWidth>
      <CellFixedWidth width="5" align="center">
        {{ shipStore.countShipsInFleet(item.id) }}
      </CellFixedWidth>
      <CellFixedWidth width="6" align="center">
        {{ shipStore.countShipsInFleetCatchingUp(item.id) }}
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <Position v-if="item.xPos !=0 && item.yPos !=0" :x="item.xPos" :y="item.yPos" @click.stop></Position>
        <span v-else>N/A</span>
      </CellFixedWidth>
      <CellFixedWidth width="10" align="center">
        {{ item.currentActionName }}
        {{ item.actions.length > 1 ? '+' + (item.actions.length-1) : ''}}
      </CellFixedWidth>
    </template>

  </FerionDataTable>
</template>

<style scoped lang="scss">

</style>