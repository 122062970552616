import axios from 'axios'
import type { AxiosResponse } from 'axios';
import {useGameStore} from "@/stores/gameStore";
import {debounce, forEach} from 'lodash';
import {useNewsStore} from "@/stores/newsStore.ts";
import { bool } from 'yup'
import * as Sentry from "@sentry/vue";

// Somewhere in your code
Sentry.captureException(new Error("This is a test error for Sentry"));


export default class Backend {
    private baseUrl = import.meta.env.VITE_API_URL
    private gameStore = useGameStore()
    private newsStore = useNewsStore()
    private debouncedPostRequest: (path: string, data: any) => void;

    constructor() {

        //get cookie with name playerToken
        const playerToken = this.getCookie('playerToken')
        if (playerToken) {
            this.gameStore.playerToken = playerToken
        } else {
            window.location.href = import.meta.env.VITE_API_URL + 'become.php'
        }

        // Define a debounced wrapper for `postRequest` here
        this.debouncedPostRequest = debounce((path: string, data: any) => {
            this.postRequest(path, data).then(response => {
                console.log(response);
            }).catch(error => {
                Sentry.captureException(error);
                console.error(error);
            });
        }, 100); // Millisecondnames

    }

    /**
     * Check if the token is valid and get the user id
     */
    public async auth(): Promise<void> {
        return this.getRequest('auth/api').then((response) => {
            if (response) {
                this.gameStore.authPlayerId = response.data.player_id
                console.log('Player ID: ' + this.gameStore.authPlayerId)
            }
        }).catch((error) => {
            // Redirect to login
            window.location.href = import.meta.env.VITE_API_URL + 'become.php'
        })
    }

    /**
     * Request init client data
     */
    public async syncGameState(): Promise<void> {
        return this.getRequest('data/sync-game-state').then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    public async purchaseMinistryCandidate(ministryCode: string, candidateCode: string): Promise<void> {
        const data = {
            ministryCode: ministryCode,
            candidateCode: candidateCode
        }
        return this.postRequest('/government/cabinet/purchase', data).then((response) => {
            return response
        })
    }

    /**
     * Get a battle report
     *
     * @param battleReportId
     */
    public async getBattleReport(battleReportId: number): Promise<void> {
        return this.getRequest('battle-report/' + battleReportId).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    public async getLatestNews(fromId: number): Promise<void> {
        let path = 'news/last/30'
        if(fromId > 0) {
            path = 'news/last/30/' + fromId
        }
        this.getRequest(path).then((response) => {
            this.newsStore.firstLoad = true

            // if empty response, we have no more news
            if(response.data.length === 0) {
                this.newsStore.loadMoreDone = false
                return
            }
            forEach(response.data, (newsItem) => {
                console.log('news',newsItem)
                this.newsStore.add(newsItem)
            })
            return
        }).catch((error) => {
            console.log(error)
        })
    }

    public async setNewsRead(): Promise<void> {
        const data = {}
        return this.postRequest('news/read', data).then((response) => {
            return response
        })
    }

    /**
     * Load game assets
     */
    public async loadAssets(): Promise<void> {
        return this.getRequest('data/assets').then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Update planet population settings
     *
     * @param planetIds
     * @param populationFood
     * @param populationScience
     * @param populationProduction
     */
    public async updatePlanetPopulation(
      planetIds: Array<number>,
      populationFood: number,
      populationScience: number,
      populationProduction: number
    ): Promise<void> {

        const data = {
            planet_ids: planetIds,
            population_working_on_food_percentage: populationFood,
            population_working_on_science_percentage: populationScience,
            population_working_on_production_percentage: populationProduction
        }

        this.debouncedPostRequest('planet/population-config', data);
    }

    /**
     * Verify ship design
     *
     * @param components
     */
    public async verifyShipDesign(
      components: any
    ): Promise<void> {
        const data = {
            components: components
        }
        return this.postRequest('ship-design/verify', data).then((response) => {
            return response
        })
    }

    /**
     * Create ship design
     *
     * @param shipDesignName
     * @param components
     */
    public async createShipDesign(
        shipDesignName: string,
        components: any
    ): Promise<void> {
        const data = {
            ship_design_name: shipDesignName,
            components: components
        }
        return this.postRequest('ship-design/create', data).then((response) => {
            return response
        })
    }

    /**
     * Delete ship request
     *
     * @param shipGroupId
     */
    public async deleteShipGroup(
        shipGroupId: number
    ): Promise<void> {
        return this.deleteRequest('ship-group/' + shipGroupId).then((response) => {
            return response
        })
    }

    /**
     * Create ship group
     *
     * @param shipGroupName
     */
    public async createShipGroup(
        shipGroupName: string
    ): Promise<void> {
        const data = {
            ship_group_name: shipGroupName
        }
        return this.postRequest('ship-group', data).then((response) => {
            return response
        })
    }

    /**
     * Delete ship design
     *
     * @param shipDesignId
     */
    public async deleteShipDesign(
        shipDesignId: number
    ): Promise<void> {
        return this.deleteRequest('ship-design/' + shipDesignId + '/delete').then((response) => {
            return response
        })
    }

    /**
     * Update planet tax settings
     *
     * @param planetId
     * @param taxPercentage
     */
    public async updatePlanetTax(
        planetId: number,
        taxPercentage: number
    ): Promise<void> {
        const data = {
            planet_id: planetId,
            tax_percentage: taxPercentage
        }
        this.debouncedPostRequest('planet/tax-config', data);
    }

    /**
     * Define what the planet is building, or add to queue
     *
     * @param planetId
     * @param productionObjectId
     */
    public async setPlanetProductionObject(
        planetId: number,
        productionObjectId: number
    ): Promise<void> {
        const data = {
            planet_id: planetId,
            production_object_id: productionObjectId
        }
        return this.postRequest('planet/set-production-object', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Create Planet Queue Automation
     *
     * @param name
     */
    public async createPQA(
      name: string,
    ): Promise<void> {
        const data = {
            name: name
        }
        return this.postRequest('planet/pqa', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Set PQA for a planet
     *
     * @param planetIds
     * @param pqaId
     */
    public async planetSetPQA(
      planetIds: Array<number>,
      pqaId: number,
    ): Promise<void> {
        const data = {
            planetIds: planetIds,
            pqaId: pqaId,
        }
        return this.postRequest('/planet/queue-automation', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Set default PQA when settling a planet
     */
    public async setPlayerSettlePQA(
      pqaId: number
    ): Promise<void> {
        const data = {
            pqaId: pqaId
        }
        return this.postRequest('/government/cabinet/settlePQA', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Set default PQA when conquering a planet
     */
    public async setPlayerConquerPQA(
      pqaId: number
    ): Promise<void> {
        const data = {
            pqaId: pqaId
        }
        return this.postRequest('/government/cabinet/conquerPQA', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Clear production from planet
     *
     * @param planetIds
     */
    public async clearProductionFromPlanet(
      planetIds: Array<number>
    ): Promise<void> {
        const data = {
            planetIds: planetIds
        }
        return this.postRequest('planet/clear-production', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Update Planet Queue Automation
     *
     * @param id
     * @param queue
     */
    public async updatePQA(
      id: number,
      queue: []
    ): Promise<void> {
        const data = {
            id: id,
            queue: queue
        }
        this.debouncedPostRequest('planet/pqa/update', data);
    }

    /**
     * Delete Planet Queue Automation
     *
     * @param id
     */
    public async deletePQA(
      id: number
    ): Promise<void> {
        this.deleteRequest('planet/pqa/' + id).then((response) => {
            return response
        })
    }

    public async setTechTarget(
      techTargetId: number,
    ): Promise<void> {
        const data = {
            targetTechId: techTargetId
        }
        return this.postRequest('tech', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Define what ship the planet is building, or add to queue
     *
     * @param planetIds
     * @param shipDesignId
     * @param count
     * @param fleetId
     * @param clearProductionQueue
     */
    public async setPlanetProductionObjectShip(
        planetIds: number,
        shipDesignId: number,
        count: number,
        fleetId: number,
        clearProductionQueue: boolean
    ): Promise<void> {
        const data = {
            planet_ids: planetIds,
            ship_design_id: shipDesignId,
            count: count,
            fleet_id: fleetId,
            clear_production_queue: clearProductionQueue?1:0
        }
        return this.postRequest('planet/set-production-object-ship', data,true).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Cancel production object
     *
     * @param planetId
     */
    public async cancelPlanetProductionObject(
        planetId: number
    ): Promise<void> {
        const data = {
            planet_id: planetId
        }
        return this.postRequest('planet/cancel-production-object', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * remove object from build queue
     */
    public async removeProductionObjectFromQueue(
        planetId: number,
        queueId: number
    ): Promise<void> {
        const data = {
            planet_id: planetId,
            queue_id: queueId
        }
        return this.postRequest('planet/remove-production-object-from-queue', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Delete building from planet
     *
     * @param planetId
     * @param building
     */
    public async deleteBuildingFromPlanet(
        planetId: number,
        building: string
    ): Promise<void> {
        const data = {
            planet_id: planetId,
            building: building
        }
        return this.postRequest('planet/delete-building', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Update planet name
     *
     * @param planetId
     * @param planetName
     */
    public async updatePlanetName(
      planetId: number,
      planetName: string
    ): Promise<void> {
        const data = {
            planet_id: planetId,
            planet_name: planetName
        }
        return this.postRequest('planet/name', data).then((response) => {
            return response
        })
    }

    /**
     * Update ship name
     * @param shipId
     * @param shipName
     */
    public async updateShipName(
      shipId: number,
      shipName: string
    ): Promise<void> {
        const data = {
            ship_id: shipId,
            ship_name: shipName
        }
        return this.postRequest('ship/name', data).then((response) => {
            return response
        })
    }

    /**
     * Delete/Destroy Ships
     *
     * @param shipIds
     */
    public async destroyShips(
      shipIds: Array<bigint>
    ): Promise<void>  {
        const data = {
            shipIds: shipIds
        }
        return this.postRequest('ship/delete', data, true).then((response) => {
            return response
        })
    }



    /**
     * Abandon planet
     */
    public async abandonPlanet(
        planetId: number
    ): Promise<void> {
        const data = {
            planet_id: planetId
        }
        return this.postRequest('planet/abandon', data).then((response) => {
            return response
        })
    }



    /**
     * Load tooltip data
     *
     * @param path
     */
    public async loadToolTip(path: string): Promise<void> {
        return this.getRequest('tooltip/' + path).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Load map area
     *
     * @param x1
     * @param y1
     * @param x2
     * @param y2
     */
    public async loadMapArea(x1: number, y1: number, x2: number, y2: number): Promise<void> {
        return this.getRequest('map/load/' + x1 + '/' + y1 + '/' + x2 + '/' + y2).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Load map coordinate
     *
     * @param x
     * @param y
     */
    public async loadMapCoordinate(x: number, y: number): Promise<void> {
        return this.getRequest('map/coordinate/' + x + '/' + y).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Load planets in range
     */
    public async getPlanetsVisible(): Promise<void> {
        return this.getRequest('map/planets').then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Create fleet
     */
    public async createFleet(name: string): Promise<void> {
        const data = {
            name: name
        }
        return this.postRequest('fleet/create', data).then((response) => {
            return response
        })
    }

    /**
     * Disband fleet
     */
    public async disbandFleet(fleetId: number): Promise<void> {

        return this.deleteRequest('fleet/' + fleetId, true).then((response) => {
            return response
        })
    }

    /**
     * Update fleet name
     *
     * @param fleetId
     * @param name
     */
    public async updateFleetName(fleetId: number, name: string): Promise<void> {
        const data = {
            fleetId: fleetId,
            name: name
        }
        return this.postRequest('fleet/update', data).then((response) => {
            return response
        })
    }

    /**
     * Save action queue for a fleet
     *
     * @param fleetId
     * @param actions
     */
    public async setFleetActions(fleetId: number, actions: any): Promise<void> {
        const data = {
            fleetId: fleetId,
            actions: actions
        }

        return this.postRequest('fleet/set-actions', data, true).then((response) => {
            return response
        })
    }

    /**
     * Assign fleet to ships
     */
    public async setFleetForShips(fleetId: number, shipIds: any): Promise<void> {
        const data = {
            fleetId: fleetId,
            shipIds: shipIds
        }

        return this.postRequest('fleet/attach-ships', data, true).then((response) => {
            return response
        })
    }

    /**
     * Remove ships from fleet
     * @param shipIds
     */
    public async detachShipsFromFleet(shipIds: any): Promise<void> {
        const data = {
            shipIds: shipIds
        }

        return this.postRequest('fleet/detach-ships', data, true).then((response) => {
            return response
        })
    }

    /**
     * Assign group to ships
     */
    public async attachShipsToGroup(groupId: number, shipIds: any): Promise<void> {
        const data = {
            groupId: groupId,
            shipIds: shipIds
        }

        return this.postRequest('ship-group/attach-ships', data, true).then((response) => {
            return response
        })
    }

    /**
     * Remove ships from group
     * @param shipIds
     */
    public async detachShipsFromGroup(shipIds: any): Promise<void> {
        const data = {
            shipIds: shipIds
        }

        return this.postRequest('ship-group/detach-ships', data, true).then((response) => {
            return response
        })
    }

    /**
     * Save action queue for a ship
     *
     * @param ships
     * @param actions
     */
    public async setShipActions(ships: any, actions: any): Promise<void> {
        const data = {
            ships: ships,
            actions: actions
        }

        return this.postRequest('ship/set-actions', data, true).then((response) => {
            return response
        })
    }

    /**
     * Clear action queue for a ship
     *
     * @param ships
     */
    public async clearShipActions(ships: any): Promise<void> {
        const data = {
            ships: ships
        }

        return this.postRequest('ship/clear-actions', data, true).then((response) => {
            return response
        })
    }


    /**
     * Get queued actions for a ship
     *
     * @param shipId
     */
    public async getShipQueuedActions(shipId: number): Promise<void> {
        return this.getRequest('ship/queued-actions/' + shipId).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    public async getStatsDataForKey(key: string): Promise<void> {
        return this.getRequest('stats/' + key).then((response) => {
            return response.data
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Declare war
     *
     * @param playerId
     */
    public async relationDeclareWar(playerId: number): Promise<void> {
        const data = {
            playerId: playerId
        }

        return this.postRequest('player/relation/declare-war', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * request/accept friendship
     *
     * @param playerId
     * @param type
     */
    public async relationRequest(playerId: number, type: string): Promise<void> {
        const data = {
            playerId: playerId,
            type: type
        }

        return this.postRequest('player/relation/request', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * reject friendship request
     */
    public async relationReject(playerId: number,type: string): Promise<void> {
        const data = {
            playerId: playerId,
            type: type
        }

        return this.postRequest('player/relation/reject-request', data).then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
        })
    }

    /**
     * Post Request
     *
     * @param path
     * @param data
     * @param longRequest
     * @protected
     */
    protected postRequest (path: string, data: any, longRequest: boolean = false): Promise<any> {
        return axios.post(this.baseUrl + path, data, {
            headers: {
                'X-Auth-Token': this.gameStore.playerToken,
                'Request-Id' : longRequest ? this.gameStore.setPendingRequest():''
            }
        }).then((response) => {
            return response
        }).catch((error) => {
            if(error.response.status === 500) {
                Sentry.captureException(error);
                console.error(error)
                this.gameStore.gameOutOfSync = true
                this.gameStore.backendError = error.response ?? error
            }
            throw error
        })
    }



    /**
     * Get Request
     *
     * @param path
     * @protected
     */
    protected getRequest (path: string): Promise<any> {
        console.log('getRequest to ' + path)
        return axios.get(this.baseUrl + path, {
            headers: {
                'X-Auth-Token': this.gameStore.playerToken
            }
        }).then((response) => {
            return response
        }).catch((error) => {
            console.error(error)
            if(error.response.status && error.response.status === 500) {
                Sentry.captureException(error);
                this.gameStore.gameOutOfSync = true
                this.gameStore.backendError = error.response ?? error
                throw error
            }
        })
    }

    /**
     * Delete Request
     *
     * @param path
     * @param longRequest
     * @protected
     */
    protected deleteRequest (path: string,longRequest: boolean = false): Promise<any> {
        console.log('deleteRequest to ' + path)
        return axios.delete(this.baseUrl + path, {
            headers: {
                'X-Auth-Token': this.gameStore.playerToken,
                'Request-Id' : longRequest ? this.gameStore.setPendingRequest():''
            }
        }).then((response) => {
            return response
        }).catch((error) => {
            if(error.response.status === 500) {
                Sentry.captureException(error);
                this.gameStore.gameOutOfSync = true
                this.gameStore.backendError = error.response ?? error
                throw error
            }
        })
    }

    /**
     * Handle Error
     */
    protected handleError (error: AxiosResponse): void {
        alert(error)
    }

    protected getCookie (name: string): string | null {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null
        return null
    }

}
