<script setup lang="ts">

import type Tech from '@/types/Tech.ts'
import { computed, nextTick, onMounted, ref } from 'vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import Icon from '@/components/Objects/Icons/Icon.vue'
import TechBuildingInfo from '@/components/Sections/Research/TechInfo/TechBuildingInfo.vue'
import TechShipComponentInfo from '@/components/Sections/Research/TechInfo/TechShipComponentInfo.vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import { formatShort } from '@/models/formatNumber.ts'

const gameStore = useGameStore()
const assetStore = useAssetStore()

const props = defineProps<{
  tech: Tech
}>();

const top = ref(0)
const left = ref(0)
const techCostToolTip = ref(false)
const techBuildingToolTip = ref(false)
const techBuildingSelected = ref(String)
const techShipComponentToolTip = ref(false)
const techShipComponentSelected = ref(String)

const techStatus = computed(() => {
  if(gameStore.player.currentTechId === props.tech.id) {
    return {color:'cyan',icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat text-cyan-500" viewBox="0 0 16 16">' +
        '  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>' +
        '  <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>' +
        '</svg>'};
  }
  if(gameStore.playerTechs.has(props.tech.id)) {
    return {color:'green',icon:'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle text-green-500" viewBox="0 0 16 16">' +
        '  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>' +
        '  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>' +
        '</svg>'};
  }
  const targetTech = assetStore.findTechById(gameStore.player.targetTechId)
  if (targetTech && targetTech.requirementTree) {
    for (const requirementTechId of targetTech.requirementTree) {
      if (requirementTechId === props.tech.id) {
        console.log(requirementTechId, props.tech.id);
        return {color:'yellow',icon:'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hourglass text-yellow-500" viewBox="0 0 16 16">' +
            '  <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5m2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2z"/>' +
            '</svg>'};
      }
    }
  }
  return {color:'red',icon:''};
});

onMounted(() => {
  top.value = 10 + ((props.tech.treeRow-1) * 15)
  left.value = 10 + ((props.tech.treeColumn-1) * 40)
});


</script>

<template>
  <div
    :class="'tech border border-slate-700 flex box-border shadow-sm shadow-' + techStatus.color + '-600'"
    :style="'top:' + top + 'rem;left:' + left + 'rem'"
  >
    <div class="flex-shrink">
    <ModalTrigger type="tech" :data="{'techId': props.tech.id}">
      <ImageRounded
        type="tech"
        source-size="128"
        :file="props.tech.code"
        :size="6"
        @mouseover="techCostToolTip = true"
        @mouseleave="techCostToolTip = false"
      />
    </ModalTrigger>
    </div>
    <div class="bg-slate-800 w-full flex-1">
      <ModalTrigger type="tech" :data="{'techId': props.tech.id}">
        <div class="content-header flex justify-between"
             @mouseover="techCostToolTip = true"
             @mouseleave="techCostToolTip = false"
        >
          <span>{{ props.tech.name }}</span>
          <span v-html="techStatus.icon">
          </span>
        </div>
      </ModalTrigger>
      <div class="grid grid-cols-5 gap-1 pl-3 p-1.5 pt-3">
        <span v-for="benefit in props.tech.provides" :key="benefit.id">
            <span v-if="benefit.type=='buildings'">
              <ModalTrigger type="building" :data="{'buildingCode': benefit.code}">
                <ImageRounded
                  type="buildings"
                  source-size="128"
                  :file="benefit.code"
                  :size="2.5"
                  @mouseover="techBuildingToolTip = true;techBuildingSelected = benefit.code"
                  @mouseleave="techBuildingToolTip = false"
                />
              </ModalTrigger>
            </span>
            <span v-if="benefit.type=='ship_components'">
              <ModalTrigger type="shipComponent" :data="{'shipComponentCode': benefit.code}">
                <ImageRounded
                  type="ship-components"
                  source-size="128"
                  :file="benefit.code"
                  :size="2.5"
                  @mouseover="techShipComponentToolTip = true;techShipComponentSelected = benefit.code"
                  @mouseleave="techShipComponentToolTip = false"
                />
              </ModalTrigger>
            </span>
        </span>
      </div>
    </div>
    <div v-if="techCostToolTip" :class="'tech-hover p-1 bg-slate-800 w-full  border-s border-e border-b border-slate-700 shadow-sm shadow-' + techStatus.color + '-600 text-center'">
      <span class="mt-4">{{ formatShort(props.tech.cost) }}</span> <Icon :type="'science'"></Icon>
    </div>
    <div v-if="techBuildingToolTip" :class="'tech-hover bg-slate-800 w-full  border-s border-e border-b border-slate-700 shadow-sm shadow-' + techStatus.color + '-600 text-center'">
      <TechBuildingInfo :building-code="techBuildingSelected"></TechBuildingInfo>
    </div>
    <div v-if="techShipComponentToolTip" :class="'tech-hover bg-slate-800 w-full  border-s border-e border-b border-slate-700 shadow-sm  shadow-' + techStatus.color + '-600 text-center'">
      <TechShipComponentInfo :ship-component-code="techShipComponentSelected"></TechShipComponentInfo>
    </div>

  </div>

</template>

<style scoped lang="scss">

.tech {
  position: absolute;
  width:23rem;
  height:6.1rem;
}
.tech-hover {
  position: absolute;
  width: 23rem;
  left:-1px;
  top:6rem !important;
}
</style>