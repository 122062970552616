import WebSocketHandler from "@/models/websocket-handler";
import Backend from "@/models/backend";

/**
 * Authenticate our token, Start Websockets and Init the game
 */
export default class Init {
    private backend = new Backend()

    constructor () {
        console.log('Init')
        // Authenticate our token first
        this.backend.auth().then(() => {
            // The setup our websockets
            const websocketHandler = new WebSocketHandler()
            websocketHandler.waitForConnection(() => this.initGame(), 100)
        })
    }

    /**
     * Init the game loader.
     */
    private initGame () {
        // Load assets and then load sync the client data
        this.backend.loadAssets().then(() => {
            this.backend.syncGameState()
        })
    }
}
