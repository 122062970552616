<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useShipActionStore } from '@/stores/shipActionStore.ts'
import router from '@/router'
import { useGameStore } from '@/stores/gameStore.ts'
import Backend from '@/models/backend.ts'
import { useTableStore } from '@/stores/tableStore.ts'
import { useShipGroupStore } from '@/stores/shipGroupStore.ts'
import { useMapStore } from '@/stores/mapStore.ts'

const tableStore = useTableStore()
const shipActionStore = useShipActionStore()
const gameStore = useGameStore()
const shipGroupStore = useShipGroupStore()
const mapStore = useMapStore()

const menuRef = ref(null);

const props = defineProps({
  lockedToFleetId: {
    type: Number,
    default: 0
  }
});

function toggleMenu() {
  if(gameStore.pullDownMenuVisible === 'shipMultiSelectMenu') {
    closeMenu()
  } else {
    gameStore.pullDownMenuVisible = 'shipMultiSelectMenu'
  }
}

// Perform actions on the map with selected ships
const actionMap = () => {
    if (tableStore.ships.selectedRowIds.length > 0) {
      console.log('actionMap', tableStore.ships.selectedRowIds)
      shipActionStore.initShipsFromIds(tableStore.ships.selectedRowIds);
      tableStore.ships.selectedRowIds = [];
      tableStore.ships.selectedAll = false

      // Disable area selection on the map
      mapStore.disableAreaSelection();

      router.push('/map/galaxy/')
    }
}

/**
 * Assign ships to a fleet
 */
const assignToFleet = () => {
  if (tableStore.ships.selectedRowIds.length > 0) {
    gameStore.modalType = 'assignToFleet'
    gameStore.modalData = tableStore.ships.selectedRowIds;
    tableStore.ships.selectedRowIds = []
    tableStore.ships.selectedAll = false

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const liberateFromFleet = () => {
  if (tableStore.ships.selectedRowIds.length > 0) {
    const backend = new Backend();
    backend.detachShipsFromFleet(tableStore.ships.selectedRowIds)
    tableStore.ships.selectedRowIds = []
    tableStore.ships.selectedAll = false

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

/**
 * Attach selected ships to a group
 */
const attachToGroup = () => {
  if (tableStore.ships.selectedRowIds.length > 0) {
    gameStore.modalType = 'attachToGroup'
    gameStore.modalData = tableStore.ships.selectedRowIds;
    tableStore.ships.selectedRowIds = []
    tableStore.ships.selectedAll = false

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const detachFromGroup = () => {
  if (tableStore.ships.selectedRowIds.length > 0) {
    const backend = new Backend();
    backend.detachShipsFromGroup(tableStore.ships.selectedRowIds)
    tableStore.ships.selectedRowIds = []
    tableStore.ships.selectedAll = false

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const destroyFleet = () => {
  if (tableStore.ships.selectedRowIds.length > 0) {
    const backend = new Backend();
    backend.destroyShips(tableStore.ships.selectedRowIds)
    tableStore.ships.selectedRowIds = []
    tableStore.ships.selectedAll = false

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

function closeMenu() {
  gameStore.pullDownMenuVisible = '';
}

function handleClickOutside(event) {
  if (!menuRef.value.contains(event.target)) {
    closeMenu();
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>


<template>
  <div class="relative" @click.stop="toggleMenu">
    <!-- Icon Button -->
    <slot></slot>
    <!-- Dropdown Menu -->
    <div v-show="gameStore.pullDownMenuVisible === 'shipMultiSelectMenu'" ref="menuRef" class="absolute w-56 border border-slate-500 bg-slate-800 rounded z-50 mt-1">
      <div class="bg-slate-600 text-center text-slate-300">
        {{ tableStore.ships.selectedRowIds.length }} ships selected
      </div>
        <span v-if="props.lockedToFleetId === 0" class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="actionMap">
          Command on map
        </span>
        <span class="block p-1 text-sm text-red-500 hover:bg-slate-700 cursor-pointer" @click="destroyFleet">
          Destroy Ships
        </span>
        <span>
          <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="assignToFleet">
            Assign to Fleet
          </span>
          <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="liberateFromFleet">
            Liberate from Fleet
          </span>
        </span>
      <span v-if="shipGroupStore.shipGroups.size>0 && props.lockedToFleetId === 0">
          <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="attachToGroup">
            Add to group
          </span>
          <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="detachFromGroup">
            Remove from group
          </span>
        </span>
    </div>
  </div>
</template>

<style scoped>
/* Add any specific styles for your dropdown here */
</style>
