<script setup lang="ts">

import { defineProps } from 'vue'
import { useGameStore } from '@/stores/gameStore'

const props = defineProps({
  title: String
});

const gameStore = useGameStore()

const closeModal = () => {
  gameStore.modalType = ''
}

window.addEventListener('keydown', (e) => {
  if (e.key === 'Escape') {
    closeModal()
  }
})

</script>

<template>
  <div
      class="fixed bg-black bg-opacity-50 w-full h-full top-0 left-0 z-50 "
      @click="closeModal()">
    <div
        class="flex overflow-x-hidden fixed justify-center items-center w-full md:inset-0 h-max-[calc(100%-1rem)]">
      <div class="relative p-3 w-full max-w-xl max-h-full" @click.stop>
        <div class="relative rounded-lg shadow bg-slate-800">
          <div class="flex items-center justify-between p-3 md:p-3 border-b rounded-t border-gray-600">
            <h3 class="text-xl font-semibold text-subtext">
              {{ props.title }}
            </h3>
            <button
                type="button"
                @click.stop="closeModal()"
            class="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white">
            X
            </button>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>

</template>



<style scoped lang="scss">

</style>