<script setup lang="ts">

</script>

<template>
  <div class="absolute z-20 w-48">
    <div class="bg-slate-800 border-1 border-slate-700">
      <div class="content-header">Settle Legend</div>
      <div class="text-xs text-subtext p-2">The circles around free planets indicate compatibility with your home wold. Planets with good compatibility will make your people work more productive.</div>
      <div class="p-2 text-green-500">Excellent Productivity</div>
      <div class="pl-2 pb-2 text-cyan-500">Good Productivity</div>
      <div class="pl-2 pb-2 text-subtext">Normal Productivity</div>
      <div class="pl-2 pb-2 text-amber-400">Poor Productivity</div>
      <div class="pl-2 pb-2 text-red-500">Bad Productivity</div>
      <div class="text-xs text-subtext p-2"><span class="text-white">White</span> circles indicate that you have already a settler inbound for the planet. </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>