<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'

const props = defineProps({
  code: {
    type: String,
    required: true
  }
})

const assetStore = useAssetStore()
const gameStore = useGameStore()

const side = ref<'left' | 'right' | ''>('')

const data = computed(() => {
  if(props.code === 'placeholder') {
    return {
      code: 'placeholder',
      name: 'Ministry of Placeholders',
      left: {
        name: 'Placeholder',
        summary: 'This is a placeholder',
        description: 'This is a placeholder',
        cost: 1,
        image: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Test_grid_1000x1000.png'
      },
      right: {
        name: 'Placeholder',
        summary: 'This is a placeholder',
        description: 'This is a placeholder',
        cost: 1,
        image: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Test_grid_1000x1000.png'
      }
    }
  }
  return assetStore.findMinistryByCode(props.code)
})

const name = computed(() => {
  if(side.value === 'left') {
    return data.value.left.name
  } else if (side.value === 'right') {
    return data.value.right.name
  } else {
    return 'Select candidate'
  }
})

const summary = computed(() => {
  if(side.value === 'left') {
    return data.value.left.summary
  } else if (side.value === 'right') {
    return data.value.right.summary
  } else {
    return 'Select a candidate to view their description'
  }
})

const cost = computed(() => {
  if(side.value === 'left') {
    return data.value.left.cost
  } else if (side.value === 'right') {
    return data.value.right.cost
  } else {
    return data.value.left.cost
  }
})


const openPopup = () => {
  gameStore.modalType = 'ministryCandidate'
  gameStore.modalData = {
    code: data.value.code,
    candidate: data.value[(side.value as 'left' | 'right')]
  }
}

function setSide(value: 'left' | 'right' | '') {
  side.value = value
}

onMounted(() => {
  if(gameStore.playerMinisters.has(data.value.left.code)) {
    side.value = 'left'
  } else if(gameStore.playerMinisters.has(data.value.right.code)) {
    side.value = 'right'
  }
})

</script>

<template>
  <div class="bg-slate-800 border border-slate-700 relative overflow-hidden cursor-pointer" @click="openPopup" >
    <div class="p-2 bg-slate-700">{{ data.name }}</div>
    <!--- Left Candidate -->
    <div v-if="gameStore.playerMinisters.has(data.left.code)">
      <img
        :src="data.left.image"
        alt="Minister"
        class=""
      />
    </div>
    <div v-else-if="gameStore.playerMinisters.has(data.right.code)">
      <img
        :src="data.right.image"
        alt="Minister"
        class=""
      />
    </div>
    <div v-else class="image-container">
      <img
        :src="data.left.image"
        alt="Minister"
        class="image-left grayscale"
        @mouseenter="setSide('left')"
      />
      <div class="image-right-container">

        <div class="bg-blend-overlay bg-blue-500">
          <img
            :src="data.right.image"
            alt="Minister"
            class="image-right grayscale"
            :class="{
            'image-right-l': side === 'right',
            'image-right-r': side === 'left'
          }"
            @mouseenter="setSide('right')"
          />
        </div>
      </div>


    </div>
    <div class="content-header flex justify-between">
      <div>{{ name }}</div>
      <div class="flex">
        <div v-for="n in cost" :key="n">
          <div class="pl-1">
            <icon type="vip" :size="4" title="VIP Token"></icon>
          </div>
        </div>
      </div>
    </div>
    <div class="p-2 text-subtext text-left h-20">{{ summary }}</div>
  </div>
</template>

<style scoped lang="scss">
.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* Forces a square aspect ratio */
}

.image-left,
.image-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  transition: clip-path 0.5s, filter 0.3s; /* Add transition for filter */
  filter: grayscale(100%); /* Grayscale by default */
}

.image-left:hover,
.image-right:hover {
  filter: grayscale(0%); /* Remove grayscale on hover */
}

.image-right {
  clip-path: polygon(
      50% 0,
      100% 0,
      100% 100%,
      50% 100%
  );
}

.image-right-l {
  clip-path: polygon(
      10% 0,
      100% 0,
      100% 100%,
      10% 100%
  );
}

.image-right-r {
  clip-path: polygon(
      90% 0,
      100% 0,
      100% 100%,
      90% 100%
  );
}
</style>
