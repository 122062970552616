<script setup lang="ts">
import {defineProps} from 'vue';
import {useAssetStore} from "@/stores/assetStore";
import ToolTipListItem from "@/components/Interface/ToolTip/Objects/ToolTipListItem.vue";

const props = defineProps<{
  data: any
}>();

const assetStore = useAssetStore()

// Method to get building name by type
function getBuildingNameByCode(type: string) {
  const building = assetStore.findBuildingByCode(type);
  return building ? building.buildingName : 'Unknown Building'; // Assuming the object has a name property
}

</script>

<template>

  <div class="content-header">
    Planet food production
  </div>
  <table class="w-full">
    <ToolTipListItem v-if="data.from_workforce_farmers"
                     :amount="data.from_workforce_farmers"
                     text="Farmers"
    ></ToolTipListItem>
    <template v-if="data.from_buildings.length>0">
      <template v-for="building in data.from_buildings" :key="building.id">
        <ToolTipListItem :amount="building.amount"
                         :text="getBuildingNameByCode(building.building_code)"
        ></ToolTipListItem>
      </template>
    </template>
    <ToolTipListItem v-if="data.from_population_maintenance"
                     :amount="data.from_population_maintenance"
                     text="Population upkeep"
    ></ToolTipListItem>
    <ToolTipListItem v-if="data.from_tax"
                     :amount="data.from_tax"
                     text="High taxes"
    ></ToolTipListItem>
    <ToolTipListItem v-if="data.type_bonus"
                     :amount="data.type_bonus"
                     text="Best planet type for species"
    ></ToolTipListItem>
    <ToolTipListItem v-if="data.type_penalty"
                     :amount="data.type_penalty"
                     text="Worst planet type for species"
    ></ToolTipListItem>
    <ToolTipListItem v-if="data.gravity_bonus"
                     :amount="data.gravity_bonus"
                     text="Best gravity for species"
    ></ToolTipListItem>
    <ToolTipListItem v-if="data.high_gravity_penalty"
                     :amount="data.high_gravity_penalty"
                     text="Gravity too high for species"
    ></ToolTipListItem>
    <ToolTipListItem v-if="data.low_gravity_penalty"
                     :amount="data.low_gravity_penalty"
                     text="Gravity too low for species"
    ></ToolTipListItem>
    <tr>
      <td colspan="2" class="border-b border-slate-700"></td>
    </tr>
    <ToolTipListItem
        :amount="data.population_growth_per_turn"
        text="Total (Rounded)"
    ></ToolTipListItem>
  </table>

</template>

<style scoped lang="scss">

</style>