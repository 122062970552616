<script setup lang="ts">

import FleetList from '@/components/Sections/Command/Fleets/FleetList.vue'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import router from '@/router'
import { useFleetStore } from '@/stores/fleetStore.ts'
import { useTableStore } from '@/stores/tableStore.ts'

const route = useRoute();
const fleetStore = useFleetStore()

const selectedFleetId = ref(0);

const selectFleetDetails = (fleetId: number) => {
  //set Router
  router.push(`/command/fleets/${fleetId}`);
  selectedFleetId.value = fleetId;
}

//Load the first ship in the list
if (selectedFleetId.value === 0 && fleetStore.fleets.size > 0) {
  selectFleetDetails(fleetStore.fleets.values().next().value.id);
}

if(Number(route.params.fleetId) > 0) {
  // Load from router/url
  selectFleetDetails(Number(route.params.fleetId));
} else if(fleetStore.fleets.size > 0) {
  // Load the first planet in the list
  selectFleetDetails(fleetStore.fleets.values().next().value.id);
}

</script>

<template>
    <div class="bg-slate-900">
      <div class="flex flex-col md:flex-row">
        <div class="w-full md:w-2/5">
          <div class="content-height-limit-tab pt-1 pb-1">
            <FleetList></FleetList>
          </div>
        </div>
        <div class="w-full md:w-3/5 content-height-limit-tab pl-1 pt-1 pr-1">
          <div class="bg-slate-850 rounded text-white">
            <router-view></router-view>
            <div v-if="selectedFleetId === 0">
              <div class="p-2 bg-slate-800 text-subtext" >
                <h1 class="text-white pb-2">Fleets</h1>
                Fleets allow you to group ships together for easier management. They will act as one entity when moving and attacking.<br>
                <br>
                1. Create your first fleet by using the "Create" button on this page.<br>
                <br>
                2. Select ships from the "Ships" tab and select "Assign to Fleet" from the action menu. It will add the ships to your fleet.<br>
                <br>
                3. The first ship added to the fleet will automatically become the Core fleet. All ships that are on the same x,y coordinates will automatically become part of the core fleet.<br>
                <br>
                4. Any ship that is added to the fleet that is not in the location of the Core fleet will automatically fly to the core fleet as reinforcements.<br>
                <br>
                5. The core fleet will follow any command you give it.<br>
                <br>
                6. You can click on the ships tab of your fleet details and remove ships from the fleet.<br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped lang="scss">

</style>