<script setup lang="ts">

import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import ActionBarSelect from '@/components/Interface/DataTable/ActionBar/ActionBarSelect.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import { useFleetStore } from '@/stores/fleetStore.ts'
import { useTableStore } from '@/stores/tableStore.ts'
import { computed } from 'vue'
import { useShipStore } from '@/stores/shipStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import HeaderFixedWidthTextNoSort from '@/components/Interface/DataTable/Headers/HeaderFixedWidthTextNoSort.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import IconCount from '@/components/Objects/IconCount.vue'
import { useShipActionStore } from '@/stores/shipActionStore.ts'

const fleetStore = useFleetStore()
const tableStore = useTableStore()
const shipStore = useShipStore()
const gameStore = useGameStore()
const shipActionStore = useShipActionStore()

const setAction = (fleetId:number) => {
  gameStore.modalType = ''
  shipActionStore.initFleet(fleetStore.findFleetById(fleetId))
}


const fleetData = computed(() => {
  let shipCount = []

  for (let ship of shipStore.ships.values()) {
    if(ship.xPos !== gameStore.modalData.xPos || ship.yPos !== gameStore.modalData.yPos) {
      continue;
    }
    if(ship.fleetId > 0) {
      shipCount[ship.fleetId] = shipCount[ship.fleetId] ? (shipCount[ship.fleetId] + 1) : 1;
    }
  }
  return shipCount
});

// Load filtered ships list
const filters = (fleets) => {
  return Array.from(fleets.values())
    .filter((fleet) => {
      if(fleetData.value[fleet.id]) {
        return true;
      }
    })
    .filter((fleet) => {
      // If no search query, return all fleets
      if (!tableStore.mapFleets.filters.nameSearch || tableStore.mapFleets.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the fleet name (case-insensitive search)
      return fleet.name.toLowerCase().includes(tableStore.mapFleets.filters.nameSearch.toLowerCase());
    });
};

// Double-click event
const doubleClick = (id) => {
  const fleet = fleetStore.findFleetById(id);
  shipActionStore.initFleet(fleet);
  tableStore.mapFleets.selectedAll = false
  gameStore.modalType=''
};

</script>

<template>
  <div class="py-1 text-white bg-slate-900">
    <FerionDataTable
      :data="fleetStore.fleets"
      tableStoreName="mapFleets"
      :selector="false"
      :filters="filters"
      :heightLimit="'h-[calc(100vh-23.75rem)]'"
      :double-click="doubleClick"
      :click="doubleClick"
    >
      <!--- ActionBar --->
      <template #actionBar>
        <ActionBarFiller class="mr-1"></ActionBarFiller>
        <ActionBarSearch v-model="tableStore.mapFleets.filters.nameSearch" name="Name" placeHolder="Search..." class="mr-1"/>
      </template>
      <!--- Headers --->
      <template #columns="{ sort, sortColumn, sortDirection }">
        <HeaderTextFlex column="name" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
         <span class="pl-2">Name</span>
        </HeaderTextFlex>
        <HeaderFixedWidthText width="3" align="center"  column="healthPercentage" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_hit_points"></Icon>
        </HeaderFixedWidthText>
        <HeaderFixedWidthText width="3" align="center"  column="maxSpeed" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_max_speed"></Icon>
        </HeaderFixedWidthText>
        <HeaderFixedWidthText width="4" align="center" column="cargoCapacity" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_cargo_capacity"></Icon>
        </HeaderFixedWidthText>
        <HeaderFixedWidthText width="4" align="center" column="shields" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_shields"></Icon>
        </HeaderFixedWidthText>
        <HeaderFixedWidthText width="4" align="center" column="attackShips" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_attack_ships"></Icon>
        </HeaderFixedWidthText>
        <HeaderFixedWidthText width="4" align="center" column="attackPlanets" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_attack_planets"></Icon>
        </HeaderFixedWidthText>
        <HeaderFixedWidthText width="3" align="center" column="scanningRange" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_scanning_range"></Icon>
        </HeaderFixedWidthText>
        <HeaderFixedWidthText width="3" align="center" column="repair" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_repair"></Icon>
        </HeaderFixedWidthText>
        <HeaderFixedWidthText width="6" align="center" column="nrOfShips" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          Total ships
        </HeaderFixedWidthText>
        <HeaderFixedWidthTextNoSort width="8" align="center">
          Ships @ {{ gameStore.modalData.xPos }}x{{ gameStore.modalData.yPos }}
        </HeaderFixedWidthTextNoSort>
        <HeaderFixedWidthText width="10" column="currentActionName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
          Action
        </HeaderFixedWidthText>
      </template>
      <!--- Rows --->
      <template #rows="{ item }" >
        <CellFlexWidth min-width="52">
          <div class="flex-col pl-2">
            <div>{{ item.name }}</div>
            <small v-if="item.xPos != gameStore.modalData.xPos && item.yPos != gameStore.modalData.yPos" class="text-amber-400">Fleet Reinforcements</small>
            <small v-else class="text-green-500">Core Fleet</small>
          </div>
        </CellFlexWidth>
        <CellFixedWidth width="3">
          {{ item.healthPercentage }}%
        </CellFixedWidth>
        <CellFixedWidth width="3">
          <IconCount :amount="item.maxSpeed" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="4">
          <IconCount :amount="item.cargoCapacity" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="4">
          <IconCount :amount="item.shields" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="4">
          <IconCount :amount="item.attackShips" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="4">
          <IconCount :amount="item.attackPlanets" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="3">
          <IconCount :amount="item.scanningRange" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="3">
          <IconCount :amount="item.repair" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="6" align="center">
          {{ item.nrOfShips }}
        </CellFixedWidth>
        <CellFixedWidth width="8" align="center">
          {{ fleetData[item.id] }}
        </CellFixedWidth>
        <CellFixedWidth width="10" align="center">
          <span v-if="item.xPos != gameStore.modalData.xPos && item.yPos != gameStore.modalData.yPos">
            Moving to Core Fleet
          </span>
          <span v-else>
            {{ item.currentActionName }}
            {{ item.actions.length > 1 ? '+' + (item.actions.length-1) : ''}}
          </span>
        </CellFixedWidth>
      </template>
    </FerionDataTable>
  </div>
</template>

<style scoped lang="scss">

</style>