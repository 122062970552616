<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import NewsItemHeader from '@/components/Interface/Headers/NewsItemHeader.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import ToastItem from '@/components/Interface/Toast/ToastItem.vue'
import { computed } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'

const gameStore = useGameStore()

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

const size = computed(() => {
  return props.newsItem.contentData.planetSize*2
})

</script>

<template>
  <div v-if="props.toast">
    <ToastItem>
      <template #image>
        <div class="pt-1">
          <ImageRounded
            type="planet"
            source-size="64"
            :file="'planet_' + props.newsItem.contentData.planetType"
            :size="3"
            :border="false"
          />
        </div>
      </template>
      <template #text>
        You have settled {{ props.newsItem.contentData.planetName }}!
      </template>
    </ToastItem>
  </div>
  <div v-else>
    <NewsItemHeader :is-read="newsItem.isRead" :turn="newsItem.turn">
      You have settled {{ props.newsItem.contentData.planetName }}!
    </NewsItemHeader>
    <div class="bg-slate-800 p-2 text-subtext flex ">
      <div class="pr-2 flex-shrink-0 flex items-center justify-center" style="width: 8rem; height: 8rem; overflow: hidden; background-color:black;">
        <ImageRounded
          type="planet"
          source-size="128"
          :file="'planet_' + props.newsItem.contentData.planetType"
          :size="size"
          :border="false"
        />
      </div>
      <div class="flex-grow ps-2">
        <div class="pb-2">
          <p class="pb-2">You have settled {{ props.newsItem.contentData.planetName }}! The colony ship has landed and has become part of the new settlement.</p>
          <p class="pb-2" v-if="props.newsItem.contentData.planetSize === gameStore.player?.homeWorldPlanetSize">The planet's gravity is perfect for your people, they will be <span class="text-green-500">more productive</span>.</p>
          <p class="pb-2" v-if="props.newsItem.contentData.planetSize === gameStore.player?.homeWorldOppositePlanetSize">The planet's gravity not what your people are used to, they will be <span class="text-red-500">less productive</span>.</p>
          <p class="pb-2" v-if="props.newsItem.contentData.planetType === gameStore.player?.homeWorldPlanetType">This is a {{props.newsItem.contentData.planetTypeName}} planet, which is a very comfortable environment for your people, it will make them <span class="text-green-500">more productive</span>.</p>
          <p class="pb-2" v-if="props.newsItem.contentData.planetType === gameStore.player?.homeWorldOppositePlanetType">This is a {{props.newsItem.contentData.planetTypeName}} planet, which is very difficult for your people to live on, it will make them <span class="text-red-500">less productive</span>.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>