import {defineStore} from 'pinia'
import { markRaw, ref, shallowRef } from 'vue'
import {useGameStore} from '@/stores/gameStore.ts'
import Backend from "@/models/backend.ts";
import * as THREE from 'three';
import { number } from 'yup'
import { MapCrossHairUpdate } from '@/models/Map/MapCrosshair.ts'
import { ShipActionPath } from '@/models/Map/MapShipActionPath.ts'
import { useTableStore } from '@/stores/tableStore.ts'

interface Sector {
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    loaded: boolean
}

export const useMapStore = defineStore('mapStore', {
    state: () => ({
        sectors: [] as Sector[], // List of sectors, used to load map data in chunks
        mapViewPort: ref<HTMLElement | null>(null),
        scene: markRaw(new THREE.Scene()),
        zoomFactor: 1 as number,
        gotoX: 0 as number, //Jump to this coordinate
        gotoY: 0 as number, //Jump to this coordinate
        tooltipX: 0 as number, //For which coordinate the tooltip is shown
        tooltipY: 0 as number, //For which coordinate the tooltip is shown
        mapDetailsX: 0 as number, //For which coordinate the details are shown
        mapDetailsY: 0 as number, //For which coordinate the details are shown
        toolTipVisible: false,
        toolTipType: 'coordinate',
        playerNameTooltipVisible: false,
        playerNameTooltipOwnerId: 0,
        playerNameTooltipShipCount: 0,
        playerNameTooltipXPos: 0,
        playerNameTooltipYPos: 0,
        mouseXPos: 0 as number,
        mouseYPos: 0 as number,
        // Area Selector
        areaSelecting: false as boolean,
        areaStartTime: 0 as number,
        areaActionSelecting: false as boolean,
        areaBox: shallowRef<THREE.Line | null>(null),
        areaStart: { x: number, y: number },
        areaEnd: { x: number, y: number },
        areaBoxTL: { x: number, y: number },
        areaBoxRB: { x: number, y: number },
        areaMouseEnd: { x: number, y: number },
        etaSpeed: 1 as number,
        crossHairVisible: false,
        shipActionPath: {} as ShipActionPath,
        mapSideBarVisible: false,
        mapSideBarTab: '' as string,
    }),
    actions: {
        disableAreaSelection() {
            this.areaSelecting = false;
            if(this.areaBox) {
                this.areaBox.visible = false;
            }
            this.areaActionSelecting = false;

            const tableStore = useTableStore();
            tableStore.planets.selectedRowIds = [];
            tableStore.ships.selectedRowIds = [];

        },
        focusMap(x: number, y: number) {
            this.gotoX = x;
            this.gotoY = y;
            this.crossHairVisible = true;
            new MapCrossHairUpdate(this.scene,this.zoomFactor)
        },
        /**
         * Generate sectors to be loaded on the map
         */
        populateSectors() {
            const gameStore = useGameStore();
            const numSectors = gameStore.galaxyTileGridSize / gameStore.sectorTileGridSize;
            for (let x = 0; x < numSectors; x++) {
                for (let y = 0; y < numSectors; y++) {
                    const x1 = x * gameStore.sectorTileGridSize;
                    const y1 = y * gameStore.sectorTileGridSize;
                    const x2 = x1 + gameStore.sectorTileGridSize - 1;
                    const y2 = y1 + gameStore.sectorTileGridSize - 1;
                    this.sectors.push({ x1, y1, x2, y2, loaded: false});
                }
            }
            console.log('Number of sectors:', this.sectors.length);
        },


        /**
         * Load visible sectors on viewport
         *
         */
        loadVisibleSectors() {
            const mapStore = useMapStore();

            let x = mapStore.mouseXPos;
            let y = mapStore.mouseYPos;
            if (x === 0 && y === 0) {
                x = mapStore.gotoX;
                y = mapStore.gotoY;
            }

            // Sort sectors by distance to the center point (x, y) to load them in a spiraling manner
            const sortedSectors = this.sortSectorsByDistance(this.sectors, x, y);

            const loadSectorData = async (sector: Sector) => {
                if (!sector.loaded) {
                    // Load sector data
                    sector.loaded = true;
                    const backend = new Backend();
                    try {
                        const response = await backend.loadMapArea(sector.x1, sector.y1, sector.x2, sector.y2);
                        // handle response if needed
                    } catch (error) {
                        console.error('Error loading sector:', error);
                    }
                }
            };

            const loadVisibleSectorsSequentially = async () => {
                for (const sector of sortedSectors) {
                    await loadSectorData(sector);
                }
            };

            loadVisibleSectorsSequentially();
        },

        sortSectorsByDistance(sectors: Sector[], centerX: number, centerY: number): Sector[] {
            return sectors.sort((a, b) => {
                const aCenterX = (a.x1 + a.x2) / 2;
                const aCenterY = (a.y1 + a.y2) / 2;
                const bCenterX = (b.x1 + b.x2) / 2;
                const bCenterY = (b.y1 + b.y2) / 2;

                const distanceA = (aCenterX - centerX) ** 2 + (aCenterY - centerY) ** 2;
                const distanceB = (bCenterX - centerX) ** 2 + (bCenterY - centerY) ** 2;

                return distanceA - distanceB;
            });
        }



    }
});
