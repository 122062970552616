<script setup lang="ts">
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import Backend from '@/models/backend.ts'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import { useShipGroupStore } from '@/stores/shipGroupStore.ts'
import ActionBarBlock from '@/components/Interface/DataTable/ActionBar/ActionBarBlock.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import Position from '@/components/Interface/Position.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import ShipHealth from '@/components/Objects/Progressbar/ShipHealth.vue'
import IconCount from '@/components/Objects/IconCount.vue'
import HeaderFixedWidthTextNoSort from '@/components/Interface/DataTable/Headers/HeaderFixedWidthTextNoSort.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import { useGameStore } from '@/stores/gameStore.ts'

const backend = new Backend()
const gameStore = useGameStore()
const shipGroupsStore = useShipGroupStore()

// Load filtered ships list
const filters = (shipGroups) => {
  return Array.from(shipGroups.values())
    .filter((shipGroup) => {
      return true
    })
};

const createGroup = () => {
  gameStore.modalType = 'createShipGroup'
}

const deleteGroup = (id:number) => {
  backend.deleteShipGroup(id)
}

</script>

<template>
  <SmallModel title="Ship Groups">
    <div class="pt-1 bg-slate-900 text-white">
      <FerionDataTable
        :data="shipGroupsStore.shipGroups"
        tableStoreName="shipGroups"
        :selector="false"
        :filters="filters"
        :heightLimit="'h-96'"
        :footerBar="false"
        :noResultMessage="'No groups found, press create to make a new group'"
      >
        <!--- ActionBar --->
        <template #actionBar>
          <ActionBarBlock class="mr-1 pl-2 flex items-center">
            <StandardButton @click="createGroup">Create</StandardButton>
          </ActionBarBlock>
          <ActionBarFiller class=""></ActionBarFiller>
        </template>
        <!--- Columns --->
        <!--- Headers --->
        <template #columns="{ sort, sortColumn, sortDirection }">
          <HeaderTextFlex column="name" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
            <span class="pl-2">Name</span>
          </HeaderTextFlex>
          <HeaderFixedWidthTextNoSort width="5" align="center">
            Action
          </HeaderFixedWidthTextNoSort>
        </template>
        <!--- Rows --->
        <template #rows="{ item }" >
          <CellFlexWidth min-width="96">
            <span class="pl-2">{{ item.name }}</span>
          </CellFlexWidth>
          <CellFixedWidth width="5">
            <StandardButton @click.once="deleteGroup(item.id)" color="red">x</StandardButton>
          </CellFixedWidth>
        </template>
      </FerionDataTable>
    </div>
  </SmallModel>
</template>


<style scoped lang="scss">

</style>