<script setup lang="ts">

import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { computed } from 'vue'

const playerRelations = usePlayerRelationStore()

const pills = computed(() => {
  return playerRelations.getAllRelationsWithPendingActions()
})

const tabs = [
  {
    'name': 'Friends',
    'target' : '/diplomacy/friend',
    'activeKey': 'DiplomacyFriend',
    'pill': 'friend'
  },
  {
    'name': 'Enemies',
    'target' : '/diplomacy/enemy',
    'activeKey': 'DiplomacyEnemy',
    'pill': 'enemy'
  },
  {
    'name': 'Allies',
    'target' : '/diplomacy/ally',
    'activeKey': 'DiplomacyAlly',
    'pill': 'ally'
  },
  {
    'name': 'Neutral',
    'target' : '/diplomacy/neutral',
    'activeKey': 'DiplomacyNeutral',
    'pill': 'neutral'
  },
  {
    'name': 'Alliances',
    'target' : '/diplomacy/alliances',
    'activeKey': 'DiplomacyAlliances',
    'pill': 'alliance'
  }
]

</script>

<template>
  <div class="text-sm font-medium text-center border-b text-gray-400 border-gray-700">
    <ul class="flex flex-wrap">
      <li class="me-2">
        <router-link
          v-for="tab in tabs"
          :key="tab.name"
          :to="tab.target"
          :class="{
            'active text-blue-500 border-blue-500': $route.name?.toString().startsWith(tab.activeKey),
            'text-gray-500 border-transparent': !$route.name?.toString().startsWith(tab.activeKey),
            'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
          }"
        >
          {{ tab.name }} <span class="text-white" v-if="playerRelations.countByRelation(tab.pill)>0">({{ playerRelations.countByRelation(tab.pill) }})</span>
          <span v-if="pills.has(tab.pill)"
            class="rounded bg-red-500 text-white text-xs px-1 ml-1"
          >{{ pills.get(tab.pill) }}</span>
        </router-link>
      </li>
    </ul>
  </div>
  <router-view></router-view>
</template>

<style scoped lang="scss">

</style>